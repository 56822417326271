import React, { FC, useContext, useEffect } from "react";
import { Button, Formblock, Notifier, Wizard, WizardScreen } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import { MiterAPI } from "team-portal/utils/miter";
import AppContext from "team-portal/contexts/app-context";
import { useForm, useWatch } from "react-hook-form";
import * as vals from "team-portal/utils/validators";
import { ESignatureInputValue } from "ui/form/ESignatureInput";

import styles from "./ESignatureModal.module.css";
import useWizard from "ui/modal/useWizard";
import { getBrowser, getOS, markFilesAsViewed } from "miter-utils";
import {
  CreateESignatureParams,
  SignESignatureRequestParams,
} from "../../../backend/services/esignature-item-service";
import { ESignatureItem } from "dashboard/miter";

type ESignatureForm = {
  esignature?: ESignatureInputValue;
};

type ESignatureModalProps = {
  esignatureRequest?: ESignatureItem;
  document?: FilePickerFile;
  onCancel: () => void;
  onFinish: () => void;
};

type ESignatureFormProps = ESignatureModalProps & {
  name: string;
};

const ESignatureModal: FC<ESignatureModalProps> = ({ document, onCancel, onFinish, esignatureRequest }) => {
  return (
    <Wizard onExit={onCancel} onComplete={onFinish}>
      <ESignatureForm
        document={document}
        name={`Sign ${document?.data?.label} Document`}
        onCancel={onCancel}
        onFinish={onFinish}
        esignatureRequest={esignatureRequest}
      />
    </Wizard>
  );
};

export default ESignatureModal;

const ESignatureForm: FC<ESignatureFormProps> = ({ name, document, onFinish, esignatureRequest }) => {
  const { activeTM } = useContext(AppContext);
  const { setCanNext } = useWizard();

  /*********************************************************
   * Form variables
   **********************************************************/

  const form = useForm<ESignatureForm>();
  const { errors, watch, formState, trigger } = form;
  const { dirtyFields } = formState;

  // Keep track of form data
  const formData = watch();
  useWatch({ control: form.control });

  /*********************************************************
   * useEffect's
   **********************************************************/

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [errors]);

  // Trigger form validation when form data changes
  useEffect(() => {
    trigger();
  }, [JSON.stringify(formData)]);

  /*********************************************************
   *  Wizard handlers
   **********************************************************/
  const onNext = async () => {
    if (Object.keys(dirtyFields).length > 0) {
      await form.handleSubmit(save)();
    }

    // We need to throw an error to prevent the wizard from moving to the next screen
    if (Object.keys(errors).length > 0) {
      throw new Error("Form is not valid");
    }
  };

  /*********************************************************
   * Backend / data functions
   **********************************************************/
  const buildESignatureParams = async (
    data: ESignatureForm
  ): Promise<CreateESignatureParams | SignESignatureRequestParams> => {
    if (!activeTM?.company._id) {
      throw new Error("Company not found.");
    }

    if (!esignatureRequest) {
      throw new Error("Unable to find esignature request for this document. Please contact support.");
    }

    if (!data?.esignature?.data) {
      throw new Error(`Please enter your full name to sign this document.`);
    }

    return {
      company_id: activeTM?.company._id,
      // @ts-expect-error ts complains about no ip_address being passed in
      signature: {
        image: data.esignature.data,
        device: {
          type: "desktop",
          os: getOS() ?? undefined,
        },
        application: {
          name: "team-portal",
        },
      },
    };
  };

  const save = async (data: ESignatureForm) => {
    try {
      const formattedESignatureParams = await buildESignatureParams(data);
      const res = await MiterAPI.esignature_items.requests.sign(
        esignatureRequest!._id,
        formattedESignatureParams
      );
      if (res.error) throw new Error(res.error);

      Notifier.success("Document signed successfully");
      onFinish();
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      console.log(`Error signing document: ${err.message}`);
    }
  };

  const viewDocument = async () => {
    try {
      if (!document?.data?._id) throw new Error("Error getting document. Please contact support.");

      const responseData = await MiterAPI.files.retrieve(document.data._id);
      if (responseData.error) throw responseData.error;

      const browser = getBrowser();

      if (browser?.includes("Safari")) {
        window.location.href = responseData.url;
      } else {
        window.open(responseData.url, "_blank");
      }
      markFilesAsViewed([document.data._id]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an getting the link to the signed document. Please contact support.");
    }
  };

  return (
    <WizardScreen name={name} nextButtonText="Sign and submit" onNext={onNext}>
      <div className={styles["modal-content"]}>
        <Button
          className={"button-2"}
          onClick={viewDocument}
          style={{ margin: 0, marginBottom: 30, marginTop: 10, padding: "20px 35px", fontSize: 16 }}
        >
          View Document
        </Button>
        <Formblock
          type="esignature"
          name="esignature"
          label="Signature*"
          form={form}
          editing={true}
          className="modal wizard"
          rules={{ validate: vals.esignatureRequired }}
          onError={Notifier.error}
        />
      </div>
    </WizardScreen>
  );
};
