import React, { useContext, useEffect, useState } from "react";
import AppContext from "team-portal/contexts/app-context";
import { MiterAPI } from "team-portal/utils/miter";
import { BasicModal, Notifier, usdString } from "ui";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

export type FringeContribution = {
  _id: string;
  label: string;
  amount: number;
  amountString: string;
};

type Props = {
  payrollId: string;
  paydayString: string;
  hide: () => void;
};

export const FringesPaystubModal: React.FC<Props> = ({ payrollId, paydayString, hide }) => {
  const { activeTM } = useContext(AppContext);
  const [contributionsData, setContributionsData] = useState<FringeContribution[]>([]);
  const [loading, setLoading] = useState(true);

  const getContributions = async () => {
    if (!activeTM) return null;
    setLoading(true);
    try {
      const tmId = activeTM._id.toString();
      const response = await MiterAPI.team_member.get_contributions(tmId, payrollId);
      if (response.error) throw new Error(response.error);
      setContributionsData(
        response.map((r) => {
          const split = r.label.split(":");
          return {
            ...r,
            _id: r.label,
            label: split[1]?.trim() || split[0]?.trim() || "-",
            amountString: usdString(r.amount),
          };
        })
      );
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error retrieving the fringe contributions`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getContributions();
  }, []);

  const columnConfig: ColumnConfig<FringeContribution>[] = [
    {
      field: "label",
      headerName: "Label",
      dataType: "string",
    },
    {
      field: "amountString",
      headerName: "Amount",
      dataType: "string",
    },
  ];

  return (
    <BasicModal
      headerText={`Fringe contributions for ${paydayString}`}
      showCloseX={true}
      wrapperStyle={{ width: "80%", maxWidth: 600 }}
      onHide={hide}
      button2Text="Done"
      button2Action={hide}
    >
      <>
        <TableV2
          id="fringes-paystub-table"
          resource="fringes"
          columns={columnConfig}
          data={contributionsData}
          isLoading={loading}
          hideSearch
          hideSecondaryActions
          hideSelectColumn
          hideFooter={!contributionsData || contributionsData.length < 10}
        />
      </>
    </BasicModal>
  );
};
