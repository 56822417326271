import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier, Wizard } from "ui";
import { Option } from "ui/form/Input";
import { DateTime } from "luxon";
import { UploadCertificationWizardScreen } from "miter-components";
import AppContext from "team-portal/contexts/app-context";
import { CertificationType } from "dashboard/types/certification-types";
import { MiterAPI } from "team-portal/utils/miter";
import { truncate } from "team-portal/utils/utils";

type Props = {
  close: () => void;
};

export const UploadCertificationFromPortal: FC<Props> = ({ close }) => {
  const form = useForm();
  const { handleSubmit } = form;
  const { activeTM } = useContext(AppContext);
  const [certificationTypeOptions, setCertificationTypeOptions] = useState<Option<string>[]>([]);
  const [certificationType, setCertificationType] = useState<CertificationType>();

  useEffect(() => {
    generateCertificationTypeOptions();
  }, [activeTM]);

  if (!activeTM) return null;

  const chooseCertificationType = async (data: { certification_type_id: Option<string> }) => {
    try {
      const res = await MiterAPI.certifications.forage({
        filter: [
          {
            type: "or",
            value: [
              {
                field: "expires_at",
                value: DateTime.now().toISODate(),
                type: "string",
                comparisonType: ">=",
              },
              { field: "expires_at", value: null, type: "string" },
            ],
          },
          { field: "team_member_id", value: activeTM._id, type: "string" },
          { field: "certification_type_id", value: data.certification_type_id.value, type: "string" },
        ],
        limit: 1,
      });

      if (res.data.length > 0) {
        throw new Error(
          `${activeTM?.full_name} already has this certification ${
            res.data[0]!.expires_at
              ? `that expires on ${DateTime.fromISO(res.data[0]!.expires_at!).toFormat("MMMM d, yyyy")}.`
              : `with no expiry.`
          }`
        );
      }

      const certificationTypeRes = await MiterAPI.certification_types.retrieve(
        data.certification_type_id.value
      );

      if (!certificationTypeRes) throw new Error("Certification type not found");

      if (certificationTypeRes.error) {
        throw new Error(certificationTypeRes.error);
      }

      setCertificationType(certificationTypeRes);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  const generateCertificationTypeOptions = async () => {
    try {
      const res = await MiterAPI.certification_types.retrieve_many([
        { field: "company_id", value: activeTM.company._id },
      ]);
      if (res.error) throw res.error;
      setCertificationTypeOptions(res.map((certType) => ({ label: certType.title, value: certType._id })));
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  const renderTeamMemberModal = () => {
    if (!activeTM?.company?._id) return null;
    return (
      <div>
        <ActionModal
          headerText={truncate("Upload for " + activeTM?.full_name, 25)}
          onHide={close}
          showCancel={true}
          onCancel={close}
          showSubmit={true}
          onSubmit={handleSubmit(chooseCertificationType)}
          submitText={"Create"}
          bodyStyle={{ overflowX: "visible" }}
        >
          <div className="vertical-spacer"></div>

          <Formblock
            label="Certification*"
            type="select"
            options={certificationTypeOptions}
            form={form}
            name="certification_type_id"
            className="modal"
            editing={true}
          />
        </ActionModal>
      </div>
    );
  };

  return (
    <div>
      {certificationType ? (
        <Wizard onExit={close}>
          <UploadCertificationWizardScreen
            certificationOwner={activeTM}
            certificationType={certificationType}
            onClose={close}
            companyId={activeTM.company._id}
            name="Upload certification"
            readOnly={false}
          />
        </Wizard>
      ) : (
        renderTeamMemberModal()
      )}
    </div>
  );
};
