import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dashboardTranslation from "./dashboard";
import tabsTranslation from "./tabs";
import onboardingTranslation from "./onboarding";
import emergencyTranslation from "./emergency";
import paystubTranslation from "./paystub";
import timeoffTranslation from "./timeoff";
import profileTranslation from "./profile";
import paymentInfoTranslation from "./paymentInfo";

const allMappings = {
  ...dashboardTranslation,
  ...tabsTranslation,
  ...onboardingTranslation,
  ...emergencyTranslation,
  ...paystubTranslation,
  ...timeoffTranslation,
  ...profileTranslation,
  ...paymentInfoTranslation,
};

export type TranslationMap = {
  [key: string]: {
    en: string;
    es: string;
  };
};

const mapToi18n = (obj: TranslationMap) => {
  const i18Mapping = {
    en: {
      translation: {},
    },
    es: {
      translation: {},
    },
  };
  return Object.entries(obj).reduce((acc, curr) => {
    const [key, value] = curr;
    const { es, en } = value;
    acc.en.translation[key] = en;
    acc.es.translation[key] = es;
    return acc;
  }, i18Mapping);
};

const resources = mapToi18n(allMappings);

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: false,
  resources,
});

export default i18n;
