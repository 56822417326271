import { AggregatedForm } from "dashboard/miter";
import React, { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormSubmissionsTable from "team-portal/components/forms/FormSubmissionsTable";
import AppContext from "team-portal/contexts/app-context";
import { MiterAPI } from "team-portal/utils/miter";
import { Notifier } from "ui";

const FormSubmissions: FC = () => {
  const { activeTM } = useContext(AppContext);
  const [form, setForm] = useState<AggregatedForm>();
  const { id } = useParams();

  const getData = async () => {
    try {
      if (!activeTM) throw new Error("No team member found. Please refresh the page.");
      if (!id) throw new Error("No form id found. Please refresh the page.");
      const res = await MiterAPI.team_member.forms({
        form_id: id,
        id: activeTM?._id,
      });

      if (res.error) throw new Error(res.error);

      if (!res[0]) throw new Error("Form not found");

      setForm(res[0]);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error fetching forms. We're looking into it.");
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <div>
      {form ? (
        <div>
          <div className="page-content-header flex">
            <h1>{form.name}</h1>
          </div>

          <FormSubmissionsTable form={form} getForm={getData} />
        </div>
      ) : null}
    </div>
  );
};

export default FormSubmissions;
