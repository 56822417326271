import { BalanceEstimate } from "miter-utils";
import { DateTime } from "luxon";
import { FC } from "react";
import styles from "./TimeOffRequestSummary.module.css";

/*********************************************************
 *  TimeOffRequestSummary
 *
 *  This is a presentational component to display a basic
 *  summary of the effects of a time off request on the
 *  time off balance of an employee.
 **********************************************************/
type Props = {
  balance: number;
  requested: number;
  updated: number;
  status: string;
  unlimited: boolean;
  startDate: DateTime;
  balanceEstimate?: BalanceEstimate;
};

const TimeOffRequestSummary: FC<Props> = ({
  balance,
  requested,
  updated,
  status,
  unlimited,
  startDate,
  balanceEstimate,
}) => {
  const estimatedBalance =
    balance + (balanceEstimate?.accrualProjection || 0) - (balanceEstimate?.usageProjection || 0);

  const estimatedUpdatedBalance = estimatedBalance - requested;

  const renderStartingBalance = () => {
    // If the request is from the past, show the current balance otherwise show the estimated balance
    if (startDate < DateTime.now()) {
      return (
        <p className={styles["summary-item"]}>
          <span className={styles["summary-title"]}>Current balance</span>
          <span className={styles["summary-value"]}>
            {balance !== null && balance !== undefined ? balance + " hrs" : "N/A"}
          </span>
        </p>
      );
    } else {
      return (
        <p className={styles["summary-item"]}>
          <span className={styles["summary-title"]}>Estimated balance</span>
          <span className={styles["summary-value"]}>
            {estimatedBalance !== null && estimatedBalance !== undefined ? estimatedBalance + " hrs" : "N/A"}
          </span>
        </p>
      );
    }
  };

  return (
    <div className={styles["time-off-request-summary"]}>
      <p className={styles["summary-label"]}>Summary</p>
      <div className={styles["summary-box"]}>
        {status !== "paid" && !unlimited && <>{renderStartingBalance()}</>}
        <p className={styles["summary-item"]}>
          <span className={styles["summary-title"]}>Amount requested</span>
          <span className={styles["summary-value"]}>
            {requested !== null && requested !== undefined && !unlimited ? "- " : ""}
            {requested !== null && requested !== undefined ? requested + " hrs" : "N/A"}
          </span>
        </p>
        {status !== "paid" && !unlimited && (
          <>
            <hr></hr>
            <p className={styles["summary-item"] + " " + styles["summary-item-bold"]}>
              <span className={styles["summary-title"]}>Updated balance</span>
              <span
                className={
                  styles["summary-value"] + " " + (updated && updated < 0 ? styles["summary-danger"] : "")
                }
              >
                {estimatedUpdatedBalance !== null && estimatedUpdatedBalance !== undefined
                  ? "= " + estimatedUpdatedBalance + " hrs"
                  : "N/A"}
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default TimeOffRequestSummary;
