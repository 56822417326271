import { MiterAPI, MiterFilterArray } from "team-portal/utils/miter";
import { AggregatedFile, CheckDocument, ESignatureItem, File } from "dashboard/miter";
import {
  getTeamMemberESignatureRequest,
  downloadFiles,
  zipAsync,
  getTeamMemberESignatureItem,
  markFilesAsViewed,
  getBrowser,
  openBlobThisTab,
  useEnhancedSearchParams,
} from "miter-utils";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Badge, ConfirmModal, LoadingModal } from "ui";
import { Notifier } from "ui";
import { Plus } from "phosphor-react";
import { saveAs } from "file-saver";
import AppContext from "team-portal/contexts/app-context";
import DocumentModal from "./DocumentModal";
import { FilePickerFile } from "ui/form/FilePicker";
import { ActionLink } from "ui/action-menu/ActionMenu";
import ESignatureModal from "./ESignatureModal";
import { ColumnConfig, TableActionLink, TableDropdownCellRenderer, TableV2 } from "ui/table-v2/Table";
import { FillableDocumentWizard } from "miter-components";

type FilesTableFile = AggregatedFile | File | CheckDocument;

type TeamMemberDocumentRow = {
  _id: string;
  label: string;
  doc_status:
    | "-"
    | "awaiting signature"
    | "signed"
    | "viewed"
    | "not viewed"
    | "filed"
    | "completed"
    | "pending completion"
    | "pending others";
  uploaded: string;
  created_at: number;
  expires_at?: string;
  raw_file: FilesTableFile;
};

type Props = {
  onboarding?: boolean;
};

const DocumentsTable: React.FC<Props> = ({ onboarding }) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const { activeTM, user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const { doc_id: initialDocumentID, esr_id: initialESignatureRequestID } = parsedSearchParams;

  // States related to the table
  const [docs, setDocs] = useState<FilesTableFile[]>([]);
  const [selectedRows, setSelectedRows] = useState<TeamMemberDocumentRow[]>([]);

  // States related to table actions
  const [downloading, setDownloading] = useState(false);

  // States related to file upload
  const [upload, setUpload] = useState(false);

  const [editingFile, setEditingFile] = useState<FilePickerFile | undefined>();
  const [signingFile, setSigningFile] = useState<FilePickerFile | undefined>();
  const [signingESignatureRequest, setSigningESignatureRequest] = useState<ESignatureItem>();
  const [archivingFile, setArchivingFile] = useState<FilesTableFile>();
  const [completingFile, setCompletingFile] = useState<AggregatedFile>();

  useEffect(() => {
    if (!activeTM) return;

    if (initialDocumentID && initialESignatureRequestID) {
      const document = docs.find((doc) => "_id" in doc && doc._id === initialDocumentID);
      if (!document || !("esignature_items" in document)) return;

      const esignatureRequest = getTeamMemberESignatureRequest(document, activeTM._id);
      if (!esignatureRequest) return;

      setSigningFile({ data: document });
      setSigningESignatureRequest(esignatureRequest);

      // Remove the query params from the URL
      setSearchParams({ doc_id: undefined, esr_id: undefined });
    }
  }, [parsedSearchParams, docs, activeTM]);

  const miterFilesMap = useMemo(() => {
    const map: { [key: string]: AggregatedFile | File } = {};
    return docs.reduce((acc, doc) => {
      if ("_id" in doc) {
        acc[doc._id] = doc;
      }

      return acc;
    }, map);
  }, [docs]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/

  const handleNewButtonClick = () => {
    setUpload(true);
  };

  const handleDownload = async () => {
    if (!activeTM) return;

    setLoading(true);

    const miterDocs = selectedRows.filter((row) => !row._id.includes("doc"));
    downloadFiles(
      miterDocs.map((doc) => doc._id),
      setDownloading,
      miterDocs.length > 1 ? `${activeTM.full_name} Documents.zip` : undefined // If downloading multiple files, zip them
    );
    for (const doc of miterDocs) {
      markFilesAsViewed([doc._id]);
    }

    // Download multiple tax documents 1 by 1 to not overload server
    const bufferMap: { [key: string]: Buffer } = {};
    await Promise.all(
      selectedRows
        .filter((row) => "id" in row.raw_file)
        .map(async (row) => {
          const file = row.raw_file as CheckDocument;
          const blob = await handleDownloadTaxDocument(file, true);
          if (!blob) return;

          const key = `${file.label} (${file.year}).pdf`;

          const buffer = await blob.arrayBuffer();
          bufferMap[key] = Buffer.from(buffer);
        })
    );

    if (Object.keys(bufferMap).length > 0) {
      const zippedTaxDocs = await zipAsync(bufferMap);
      const data = Buffer.from(zippedTaxDocs);

      const blob = new Blob([data], { type: "application/zip" });
      const browser = getBrowser();

      if (browser?.includes("Safari")) {
        openBlobThisTab(blob);
      } else {
        saveAs(blob, `${activeTM.full_name} Tax Documents.zip`);
      }
    }

    setLoading(false);
  };

  const handleDownloadTaxDocument = async (file: CheckDocument, multiple?: boolean) => {
    if (!activeTM) return;

    try {
      const response = await MiterAPI.team_member.retrieve_tax_document(activeTM._id, file.id);
      if (response.error) throw new Error(response.error);

      const blob = await response.blob();

      if (!multiple) {
        const browser = getBrowser();

        if (browser?.includes("Safari")) {
          openBlobThisTab(blob);
        } else {
          saveAs(blob, file.label);
        }
      }

      return blob;
    } catch (err: $TSFixMe) {
      console.error("Error downloading tax document", err);
      Notifier.error("There was an error downloading the tax document. We're looking into it.");
    }
  };

  /*********************************************************
   *  Data fetching functions
   **********************************************************/
  const buildNameCell = (fileName: string, file: FilesTableFile) => {
    const esignatureItem =
      "esignature_items" in file ? getTeamMemberESignatureItem(file, activeTM!._id) : undefined;

    let onNameClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
    if (esignatureItem) {
      if (esignatureItem.status !== "signed") {
        onNameClick = () => signFile(file);
      } else {
        const signedFile = esignatureItem.signed_document_id
          ? miterFilesMap[esignatureItem.signed_document_id]
          : undefined;

        if (!signedFile) {
          onNameClick = () => openFile(file);
        } else {
          onNameClick = () => openFile(signedFile);
        }
      }
    } else {
      onNameClick = () => openFile(file);
    }

    return (
      <a className="table-link" onClick={onNameClick}>
        {fileName}
      </a>
    );
  };

  const buildDocStatus = (file: FilesTableFile): TeamMemberDocumentRow["doc_status"] => {
    if (!activeTM) return "-";
    const isFillableDocument = "fillable_template_id" in file && !!file.fillable_template_id;
    const aggregatedFile = file as AggregatedFile;

    if ("esignature_items" in file && !isFillableDocument) {
      const esignatureItem = getTeamMemberESignatureItem(file, activeTM._id);
      const viewEvent = file.events.find(
        (event) => event.action === "viewed" && event.team_member_ids.includes(activeTM._id)
      );

      if (esignatureItem && esignatureItem.status === "signed") {
        return "signed";
      } else if (esignatureItem && esignatureItem.status !== "signed") {
        return "awaiting signature";
      } else if (viewEvent) {
        return "viewed";
      } else {
        return "not viewed";
      }
    } else if ("filed_on" in file && file.filed_on) {
      return "filed";
    }

    // TODO in future PR: support "pending others" status when TM completes their section, but
    // other users still need to complete their part
    if (isFillableDocument) {
      if (aggregatedFile.fill_status === "pending") {
        return "pending completion";
      } else if (aggregatedFile.fill_status === "complete") {
        return "completed";
      }
    }

    return "-";
  };

  const buildTableRows = (files: FilesTableFile[]): TeamMemberDocumentRow[] => {
    if (!activeTM) return [];

    const parentTypeToDocumentType = {
      team_member: "personal",
      company: "company",
      job: "job",
    };

    const signedFileIds: string[] = [];
    files.forEach((file) => {
      if (!("esignature_items" in file)) return;

      const esignatureItem = getTeamMemberESignatureItem(file, activeTM._id);
      if (!esignatureItem || esignatureItem.status !== "signed") return;

      const signedFile = esignatureItem.signed_document_id;
      if (!signedFile) return;

      signedFileIds.push(signedFile);
    });

    return files
      .filter((file) => !("_id" in file) || !signedFileIds.includes(file._id))
      .map((file) => {
        const createdAt =
          "created_at" in file ? DateTime.fromSeconds(file.created_at) : DateTime.fromISO(file.filed_on);

        const documentType =
          "parent_type" in file && file.parent_type ? parentTypeToDocumentType[file.parent_type] : "tax form";

        return {
          label: file.label || "Untitled file",
          document_type: documentType,
          _id: "id" in file ? file.id : file._id,
          uploaded: createdAt.toFormat("f"),
          doc_status: buildDocStatus(file),
          created_at: createdAt.toSeconds(),
          raw_file: file,
        };
      })
      .sort((a, b) => {
        if (a.doc_status === "awaiting signature" && b.doc_status !== "awaiting signature") return -1;
        else if (a.doc_status !== "awaiting signature" && b.doc_status === "awaiting signature") return 1;
        else return b.created_at - a.created_at;
      });
  };

  // Get the files for a user filtered by the filter button and search bar.
  const getDocuments = async () => {
    if (!activeTM) return;
    setLoading(true);

    try {
      const filter: MiterFilterArray = [
        { field: "company_id", value: activeTM.company._id },
        { field: "sensitive", comparisonType: "ne", value: true },
      ];

      onboarding
        ? filter.push({ field: "onboarding_document", value: true })
        : filter.push({
            type: "or",
            value: [
              { field: "existing_tm_document", value: true },
              { field: "parent_type", value: "team_member" },
              { field: "parent_id", value: activeTM._id },
            ],
          });

      const res = await MiterAPI.team_member.documents(activeTM._id, filter);
      if (res.error) throw Error(res.error);

      const documents: FilesTableFile[] = res.documents;
      if (!onboarding) documents.push(...res.tax_forms);

      setDocs(documents);
    } catch (e) {
      console.log("Error getting files", e);
      Notifier.error("We are unable to retrieve your files at this time. Please try again later");
    }
    setLoading(false);
  };

  const openFile = async (file: FilesTableFile) => {
    try {
      if ("_id" in file) {
        const res = await MiterAPI.files.retrieve(file._id);
        if (res.error) throw new Error(res.error);

        markFilesAsViewed([file._id]);

        const browser = getBrowser();

        if (browser?.includes("Safari")) {
          window.location.href = res.url;
        } else {
          window.open(res.url, "_blank");
        }
      } else if ("filed_on" in file) {
        handleDownloadTaxDocument(file);
      }
    } catch (e) {
      console.log(e);
      Notifier.error("There was an getting the link to the file. We're looking into it.");
    }
  };

  const compeleteFillableDoc = async (file: AggregatedFile) => {
    setCompletingFile(file);
  };

  const editFile = async (file: FilesTableFile) => {
    if (!("_id" in file)) return;
    setEditingFile({ data: file });
  };

  const signFile = async (file: FilesTableFile) => {
    if (!("_id" in file) || !("esignature_items" in file)) return;

    const esignatureRequest = getTeamMemberESignatureRequest(file, activeTM!._id);
    if (!esignatureRequest) return;

    setSigningFile({ data: file });
    setSigningESignatureRequest(esignatureRequest);
  };

  const archiveFile = async (file: FilesTableFile) => {
    if (!("_id" in file)) return;

    try {
      const res = await MiterAPI.files.delete([file._id]);
      if (res.error) throw new Error(res.error);

      Notifier.success("File deleted");
      getDocuments();
      setArchivingFile(undefined);
    } catch (e) {
      console.error("Error deleting file", e);
      Notifier.error("There was an error deleting the file. We're looking into it.");
    }
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/

  const staticActions: TableActionLink[] = [
    ...(!onboarding && activeTM?.company.settings?.documents?.enable_team_member_upload
      ? [
          {
            label: "Upload Document",
            icon: <Plus weight="bold" />,
            className: "button-2",
            loading,
            action: handleNewButtonClick,
            important: true,
          },
        ]
      : []),
  ];

  const dynamicActions: TableActionLink[] = [
    {
      label: "Download",
      className: "button-1",
      loading,
      action: handleDownload,
    },
  ];

  const renderActions = (params: { data: TeamMemberDocumentRow }) => {
    const file = params.data.raw_file;
    const isFillableDocument = "fillable_template_id" in file && !!file.fillable_template_id;
    const aggregatedFile = file as AggregatedFile;

    const actions: ActionLink[] = [
      {
        label: "View",
        action: () => openFile(file),
      },
    ];

    if (
      "esignature_items" in file &&
      file.esignature_items.length &&
      !getTeamMemberESignatureItem(file, activeTM!._id)?.signed_document_id &&
      !isFillableDocument
    ) {
      actions.push({
        label: "Sign",
        action: () => signFile(file),
      });
    }

    if ("uploader_user_id" in file && file.uploader_user_id === user?._id && !isFillableDocument) {
      actions.push({
        label: "Edit",
        action: () => editFile(file),
      });
      actions.push({
        label: "Delete",
        action: () => setArchivingFile(file),
      });
    }
    if (isFillableDocument && aggregatedFile.fill_status === "pending") {
      actions.push({
        label: "Fill",
        action: () => compeleteFillableDoc(aggregatedFile),
      });
    }

    return actions.length ? <TableDropdownCellRenderer options={actions} /> : null;
  };

  const renderType = (params: { data: TeamMemberDocumentRow }) => {
    {
      const file = params.data.raw_file;
      const isFillableDocument = "fillable_template_id" in file && !!file.fillable_template_id;

      return (
        <div className="flex">
          <Badge text="Team member" color="blue" />
          {isFillableDocument && <Badge text="Fillable" color="green" style={{ marginLeft: -3 }} />}
        </div>
      );
    }
  };

  const columns: ColumnConfig<TeamMemberDocumentRow>[] = [
    {
      field: "label",
      headerName: "File",
      dataType: "component",
      cellRenderer: (params) => {
        const file = params.data?.raw_file;

        const fileName =
          file.label || ("originalname" in file ? file.originalname : "Untitled file") || "Untitled file";

        return buildNameCell(fileName, file);
      },
    },
    {
      field: "parent_type",
      headerName: "Type",
      dataType: "component",
      cellRenderer: (params) => {
        return renderType(params);
      },
    },
    {
      field: "doc_status",
      headerName: "Status",
      dataType: "string" as const,
      displayType: "badge",
      colors: {
        "not viewed": "light-gray",
        viewed: "light-green",
        signed: "light-green",
        "pending completion": "yellow",
        completed: "light-blue",
      },
    },
    ...(!onboarding
      ? [
          {
            field: "uploaded",
            headerName: "Uploaded",
            dataType: "string" as const,
          },
        ]
      : []),
    {
      field: "actions",
      headerName: "Actions",
      displayType: "dropdown",
      cellRenderer: (params) => {
        return renderActions(params);
      },
      pinned: "right",
      minWidth: 100,
    },
  ];

  /*********************************************************
    Table data functions
  **********************************************************/
  const data = useMemo(() => {
    return buildTableRows(docs);
  }, [docs]);

  /*********************************************************
   *  Call useEffect hooks
   **********************************************************/
  useEffect(() => {
    getDocuments();
  }, []);

  /*********************************************************
    Functions to render table components
  **********************************************************/

  return (
    <div className="files-table-wrapper">
      <TableV2
        data={data}
        columns={columns}
        onSelect={setSelectedRows}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        id="documents-table"
        resource="documents"
        isLoading={loading}
      />
      {downloading && (
        <LoadingModal text={"Downloading file" + ((selectedRows?.length || 0) > 1 ? "s" : "")} />
      )}
      {(upload || editingFile) && (
        <DocumentModal
          onCancel={() => {
            setUpload(false);
            setEditingFile(undefined);
          }}
          onFinish={() => {
            getDocuments();
            setUpload(false);
            setEditingFile(undefined);
          }}
          document={editingFile}
        />
      )}
      {signingFile && (
        <ESignatureModal
          esignatureRequest={signingESignatureRequest}
          document={signingFile}
          onCancel={() => setSigningFile(undefined)}
          onFinish={() => {
            getDocuments();
            setSigningFile(undefined);
          }}
        />
      )}
      {archivingFile && (
        <ConfirmModal
          body={"Are you sure you want to delete this document?"}
          onYes={() => archiveFile(archivingFile)}
          onNo={() => setArchivingFile(undefined)}
        />
      )}
      {completingFile && activeTM && (
        <FillableDocumentWizard
          teamMember={{ ...activeTM, title: activeTM.title || "" }}
          userId={activeTM.user}
          fillableDocumentId={completingFile._id}
          onComplete={() => {
            getDocuments();
            setCompletingFile(undefined);
          }}
          onExit={() => setCompletingFile(undefined)}
          isSuperAdmin={false}
          application="team-portal"
        />
      )}
    </div>
  );
};

export default DocumentsTable;
