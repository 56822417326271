import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AppContext from "team-portal/contexts/app-context";
import { MiterAPI } from "team-portal/utils/miter";
import { BasicModal, Formblock, Notifier } from "ui";

const Dashboard: React.FC = () => {
  const { activeTM, onboardingChecklistItem, fetchUserData } = React.useContext(AppContext);
  const { t } = useTranslation<$TSFixMe>();
  const [languageModal, setLanguageModal] = useState(false);

  if (!activeTM) return null;

  const greeting = (() => {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return t("Good morning");
    } else if (curHr < 18) {
      return t("Good afternoon");
    } else {
      return t("Good evening");
    }
  })();

  const saveTM = async (data) => {
    if (!activeTM) return;
    try {
      const response = await MiterAPI.team_member.update(activeTM._id, data);
      if (response.error) throw new Error(response.error);

      await fetchUserData();
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
  };

  const renderOnboardingLink = () => {
    if (!onboardingChecklistItem) {
      return (
        activeTM.check_tm?.onboard &&
        activeTM.company?.settings?.team?.enabled_portal_tabs?.onboarding &&
        activeTM.check_tm.onboard.status !== "completed" && (
          <Link className="dashboard-link-item" to={"/onboarding"}>
            <p>{t("Finish your onboarding")}</p>
          </Link>
        )
      );
    }
    const onboardingComplete =
      onboardingChecklistItem.custom_statuses?.every((item) => item.status === "complete") &&
      onboardingChecklistItem.statuses.every((item) => item.status === "complete");

    if (!onboardingComplete) {
      return (
        activeTM.company?.settings?.team?.enabled_portal_tabs?.onboarding && (
          <Link className="dashboard-link-item" to={"/onboarding/checklist"}>
            <p>{t("Finish your onboarding")}</p>
          </Link>
        )
      );
    }

    return null;
  };

  return (
    <div>
      <div>
        <p className="dashboard-pre-title">
          {greeting}, {activeTM.first_name}
        </p>
        <h1 className="dashboard-title">{t("Welcome to your Miter portal")}</h1>
        <div className="dashboard-links">
          <div style={{ fontWeight: "bold", fontSize: 17, color: "darkslategray" }}>
            {t("Important links")}
          </div>
          <div className="dashboard-link-items">
            {renderOnboardingLink()}
            {activeTM.check_tm && (
              <Link className="dashboard-link-item" to={"/paystubs"}>
                <p>{t("Check out your most recent paystub")}</p>
              </Link>
            )}
            {activeTM.company?.settings?.team?.enabled_portal_tabs?.profile && (
              <Link className="dashboard-link-item" to={"/profile"}>
                <p>{t("Edit your personal information")}</p>
              </Link>
            )}
          </div>
        </div>
        <span className="dashboard-link-item" onClick={() => setLanguageModal(true)}>
          {t("Language")}
        </span>
      </div>
      {languageModal && (
        <BasicModal
          button2Text={t("Close")}
          button2Action={() => setLanguageModal(false)}
          headerText={t("Update language")}
          onHide={() => setLanguageModal(false)}
        >
          <Formblock
            type="select"
            name="language"
            defaultValue={activeTM?.language || "en"}
            editing={true}
            options={[
              { label: "English", value: "en" },
              { label: "Español", value: "es" },
            ]}
            onChange={async (e) => {
              saveTM({ language: e.value });
            }}
          />
        </BasicModal>
      )}
    </div>
  );
};

export default Dashboard;
