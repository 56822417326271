import React, { useState, useEffect, FC, useContext } from "react";
import { useParams } from "react-router";
import AppContext from "team-portal/contexts/app-context";
import { Loader } from "ui";
import { MiterAPI } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";
import { openBlobThisTab } from "team-portal/utils/utils";

type Params = {
  id: string;
};

const TaxForm: FC = () => {
  /*********************************************************
   *  Call Hooks and Contexts
   **********************************************************/

  const { id } = useParams<Params>();
  const { activeTM } = useContext(AppContext);

  /*********************************************************
   *  Initialize states
   **********************************************************/

  const [loading, setLoading] = useState(false);

  /*********************************************************
   *  useEffect functions
   *  - redirect to paymentws if activeTM isn't an employee
   *  - get the taxForm for the specified payroll
   **********************************************************/

  useEffect(() => {
    getTaxForm();
  }, []);

  /*********************************************************
   *  Download taxForm PDF in base64
   **********************************************************/
  const getTaxForm = async () => {
    if (!activeTM) return;
    setLoading(true);
    try {
      if (!id) throw new Error("Tax Form ID is required.");

      const res = await MiterAPI.team_member.tax_forms.retrieve(activeTM._id, id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      openBlobThisTab(blob);
    } catch (e) {
      console.error(e);
      Notifier.error("We are unable to get the tax form at this time.");
    }
    setLoading(false);
  };

  if (loading) return <Loader />;
  return null;
};

export default TaxForm;
