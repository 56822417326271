import AppContext from "team-portal/contexts/app-context";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal } from "ui";

import { MiterAPI } from "team-portal/utils/miter";
import CustomFieldValuesForm from "./CustomFieldValuesForm";
import { CustomField, CustomFieldValue } from "dashboard/miter";
import Notifier from "team-portal/utils/notifier";
import _ from "lodash";
import { cleanCustomFieldValuesBeforeSaving } from "miter-utils";
import { CustomFieldParentType } from "backend/models/custom-field";

type Props = {
  parentID: string;
  parentType: CustomFieldParentType;
  defaultValues: CustomFieldValue[];
  customFields: CustomField[];
  onFinish: (values: CustomFieldValue[]) => void;
  onHide: () => void;
};

const CustomFieldValueModal: React.FC<Props> = ({
  parentID,
  parentType,
  defaultValues,
  customFields,
  onFinish,
  onHide,
}) => {
  const form = useForm();

  const { activeTM } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const teamCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "team_member"),
    [customFields]
  );

  const saveCustomFieldValues = useCallback(
    async (data) => {
      setLoading(true);
      try {
        // Only save fields with values that have changed
        const dirtyFields = Object.keys(form.formState.dirtyFields);

        const params = cleanCustomFieldValuesBeforeSaving(
          _.pick(data, dirtyFields),
          activeTM!.company._id,
          parentID,
          parentType,
          defaultValues
        );

        if (params.length) {
          const res = await MiterAPI.custom_field_values.save(params);
          if (res.error) throw new Error(res.error);

          Notifier.success("Custom field values saved successfully");

          onFinish(res);
        } else {
          onHide();
        }
      } catch (e: $TSFixMe) {
        console.error("Error creating custom field:", e);
        Notifier.error(e.message);
      }
      setLoading(false);
    },
    [form.formState.dirtyFields, activeTM, parentID, parentType, defaultValues, onFinish]
  );

  const submit = () => {
    form.handleSubmit(saveCustomFieldValues)();
  };

  const hide = () => {
    onHide();
  };

  return (
    <ActionModal
      headerText={"Custom fields"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={hide}
      submitText={"Save"}
      onHide={hide}
      onSubmit={submit}
      loading={loading}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <CustomFieldValuesForm form={form} customFields={teamCustomFields} defaultValues={defaultValues} />
      </div>
    </ActionModal>
  );
};

export default CustomFieldValueModal;
