import React, { FC, useContext, useEffect, useState } from "react";
import { ActionModal, Formblock, Notifier } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
import { MiterAPI } from "team-portal/utils/miter";
import AppContext from "team-portal/contexts/app-context";
import { useForm } from "react-hook-form";
import { formatFilesForUpload } from "miter-utils";
import * as vals from "team-portal/utils/validators";
import { DateTime } from "luxon";

type DocumentModalForm = {
  label?: string | null;
  document?: FilePickerFile[];
  expires_at?: DateTime;
};

type Props = {
  document?: FilePickerFile;
  onCancel: () => void;
  onFinish: () => void;
};

const DocumentModal: FC<Props> = ({ onCancel, onFinish, document }) => {
  const { activeTM, user } = useContext(AppContext);
  const [saving, setSaving] = useState(false);
  const headerText = document ? `Edit ${document.data?.label}` : "Upload document";

  const form = useForm<DocumentModalForm>({
    defaultValues: {
      document: document ? [document] : [],
      label: document?.data?.label,
      expires_at: document?.data?.expires_at ? DateTime.fromISO(document.data.expires_at) : undefined,
    },
  });

  useEffect(() => {
    if (document && document?.data?.uploader_user_id !== user?._id) {
      onCancel();
    }
  }, [document]);

  const save = async (data: DocumentModalForm) => {
    setSaving(true);
    try {
      document ? await updateFile(data) : await createFile(data);
      Notifier.success("Document saved");
      onFinish();
    } catch (err: $TSFixMe) {
      console.error("Error saving document", err);
    }
    setSaving(false);
  };

  const createFile = async (data: DocumentModalForm) => {
    if (!activeTM || !data.document) return;

    const res = await MiterAPI.files.upload(
      formatFilesForUpload(data.document, activeTM.company._id, {
        parent_id: activeTM._id,
        parent_type: "team_member",
        label: data.label,
        expires_at: data.expires_at?.toISODate(),
      })
    );

    if ("error" in res) throw new Error(res.error);
  };

  const updateFile = async (data: DocumentModalForm) => {
    if (!document || !activeTM || !document.data) return;

    const res = await MiterAPI.files.update_many({
      files: [{ _id: document.data._id, label: data.label }],
    });

    if (res.error) throw new Error(res.error);
  };

  return (
    <ActionModal
      headerText={headerText}
      onHide={onCancel}
      onCancel={onCancel}
      showCancel={true}
      showSubmit={true}
      onSubmit={form.handleSubmit(save)}
      loading={saving}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="label"
          label="Document Label*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal wizard"
          maxLength={50}
          placeholder={"What do you want to call this document?"}
        />
        {!document && (
          <Formblock
            label={"Upload a document*"}
            name={"document"}
            className="modal"
            type="file"
            form={form}
            multiple={true}
            maxFilesAllowed={1}
            variant="dropzone"
            editing={true}
            labelStyle={{ marginBottom: 10, marginTop: 10 }}
            dropzoneLabel="Drag and drop or click to upload a document"
            style={{ display: "block" }}
            iconSize={"2.2rem"}
            val={vals.required}
            acceptedFileTypes={[
              "image/jpeg",
              "image/png",
              "text/csv",
              "video/mpeg",
              "video/mp4",
              "application/zip",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "applcation/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-powerpoint",
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            ]}
          />
        )}
        <Formblock
          type="datetime"
          name="expires_at"
          label="Expires at"
          labelInfo="Your administrator will recieve an email notification on the Monday before the document's expiration date."
          form={form}
          editing={true}
          className="modal wizard"
          min={DateTime.now()}
          dateOnly={true}
        />
      </div>
    </ActionModal>
  );
};

export default DocumentModal;
