import React, { useEffect, useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "team-portal/contexts/app-context";
import { MiterAPI } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";
import { TimeOffPolicy } from "dashboard/miter";
import styles from "../styles/TimeOff.module.css";
import TeamMemberTimeOffPolicy from "team-portal/components/time-off/TeamMemberTimeOffPolicy";
import TimeOffRequestsTable from "team-portal/components/time-off/TimeOffRequestsTable";
import TimeOffUpdatesTable from "team-portal/components/time-off/TimeOffUpdatesTable";
import { Loader, Toggler } from "ui";
import { useTranslation } from "react-i18next";
import { useIsActive } from "team-portal/hooks/useIsActive";

export type TimeOffPolicyWithBalanceAndLevel = TimeOffPolicy & {
  balance?: number | null;
  level_id?: string | null;
};

const TimeOff: FC = () => {
  /*********************************************************
   *  Important hooks
   **********************************************************/

  const navigate = useNavigate();
  const { activeTM } = React.useContext(AppContext);
  const { t } = useTranslation<$TSFixMe>();
  const isActive = useIsActive();

  const { view } = useParams();

  /*********************************************************
   *  Setup states
   **********************************************************/

  const [timeOffPolicies, setTimeOffPolicies] = useState<TimeOffPolicyWithBalanceAndLevel[]>([]);
  const [loading, setLoading] = useState(true);

  /*********************************************************
   *  Get time off policies for the team member
   **********************************************************/
  const getTimeOffPolicies = async () => {
    if (!activeTM) return;
    setLoading(true);
    try {
      const timeOffPolicyIDs = activeTM.time_off.policies.map((policy) => policy.policy_id);

      // If there are no policies in the array, return from this function
      if (!timeOffPolicyIDs.length) {
        setLoading(false);
        return;
      }

      // Setup the API parameters
      const filters = [
        { field: "_id", type: "_id" as const, comparsionType: "in" as const, value: timeOffPolicyIDs },
      ];

      const response = await MiterAPI.time_off.policies.retrieve_many(filters);
      if (response.error) throw Error(response.error);

      const mappedTimeOffPolicies: TimeOffPolicyWithBalanceAndLevel[] = response.map((policy) => {
        const tmPolicy = activeTM!.time_off.policies.find((p) => p.policy_id === policy._id);
        return {
          ...policy,
          balance: tmPolicy?.balance,
          level_id: tmPolicy?.level_id,
        };
      });

      setTimeOffPolicies(mappedTimeOffPolicies);
    } catch (e) {
      console.log("Error", e);
      Notifier.error(
        "We were unable to get the time off policies for this team member. Please try again later or reach out to support."
      );
    }
    setLoading(false);
  };

  /*********************************************************
   *  Config functions
   **********************************************************/
  const togglerConfig = {
    config: [
      { path: "policies", label: t("Policies") },
      { path: "requests", label: t("Requests") },
      { path: "updates", label: t("Updates") },
    ],
    handler: (v) => {
      navigate(`/time-off/${v}`);
    },
    path: view ? view : "policies",
    secondary: true,
  };

  /*********************************************************
   *  Rendering functions
   **********************************************************/
  const renderTimeOffPolicies = () => {
    if (loading) return <Loader />;
    const timeOffPolicyElements = timeOffPolicies
      .filter((policy) => !!policy.level_id)
      .map((policy) => {
        return <TeamMemberTimeOffPolicy key={policy._id} timeOffPolicy={policy} />;
      });

    return (
      <div className={styles["team-member-time-off-policies"]}>
        <div className="vertical-spacer" />
        {timeOffPolicyElements.length > 0 && (
          <div className={styles["team-member-time-off-policies-list"]}>{timeOffPolicyElements}</div>
        )}
        {timeOffPolicyElements.length === 0 && (
          <p>
            {t(
              "You are not enrolled in any time off policies at this time. If you would like to enroll in a time off policy or request time off, please reach out to your HR admin."
            )}
          </p>
        )}
      </div>
    );
  };

  const renderTimeOffRequests = () => {
    return (
      <div className={styles["team-member-time-off-requests"]}>
        <TimeOffRequestsTable timeOffPolicies={timeOffPolicies} />
      </div>
    );
  };

  const renderTimeOffHistory = () => {
    return (
      <div className={styles["team-member-time-off-history"]}>
        <TimeOffUpdatesTable />
      </div>
    );
  };

  useEffect(() => {
    getTimeOffPolicies();
  }, [!!activeTM]);

  /** Hide time off requests from dismissed team members */
  useEffect(() => {
    if (!isActive) {
      navigate("/");
    }
  }, [activeTM]);

  return (
    <div>
      <h1 className="view-title title-with-toggler">{t("Time Off")}</h1>
      <Toggler
        config={togglerConfig.config}
        secondary={false}
        toggle={togglerConfig.handler}
        active={togglerConfig.path}
      />
      {(view === "policies" || !view) && renderTimeOffPolicies()}
      {view === "requests" && renderTimeOffRequests()}
      {view === "updates" && renderTimeOffHistory()}
    </div>
  );
};

export default TimeOff;
