import React, { Dispatch, FC, SetStateAction, useContext, useMemo, useState } from "react";
import * as vals from "team-portal/utils/validators";
import _ from "lodash";
import styles from "./TimeOffRequestForm.module.css";
import { Formblock } from "ui";
import { Control } from "react-hook-form";
import { DateTime } from "luxon";
import { TimeOffPolicyWithBalanceAndLevel } from "team-portal/pages/TimeOff";
import AppContext from "team-portal/contexts/app-context";
import { PreppedAggTimeOffRequest } from "./TimeOffRequestModal";
import {
  MiterError,
  MiterFilterArray,
  TimeOffPolicy,
  TimeOffRequest,
  Policy,
  Department,
} from "dashboard/miter";
import { Option, ValidationRuleset } from "ui/form/Input";
import { getTenure } from "miter-utils";
import { useTranslation } from "react-i18next";
import { MiterAPI } from "team-portal/utils/miter";
import { useTimeOffRequestPolicy } from "miter-components/time-off/time-off-request-policy-utils";

/*********************************************************
 *  TimeOffRequestForm
 *
 *  This component houses the main form used for creating,
 *  updating, and displaying time off requests. The only
 *  input that is not included in this form is the
 *  paginated partial day scheduler.
 *
 *  This form save state locally with react-hook-form but
 *  also sends it back to the modal to maintain a  copy.
 **********************************************************/

type Props = {
  mode?: "new" | "update" | "manage";
  timeOffRequest?: PreppedAggTimeOffRequest;
  timeOffPolicies: TimeOffPolicyWithBalanceAndLevel[];
  handleScheduleStart: Dispatch<SetStateAction<DateTime | undefined>>;
  handleScheduleEnd: Dispatch<SetStateAction<DateTime | undefined>>;
  register: $TSFixMeFunction;
  control: Control;
  watch: $TSFixMeFunction;
  errors: $TSFixMe;
  setValue: $TSFixMeFunction;
  handleSubmit: $TSFixMeFunction;
};

export const required: ValidationRuleset = { required: "This field is required." };

const TimeOffRequestForm: FC<Props> = ({
  mode,
  timeOffRequest,
  timeOffPolicies,
  handleScheduleStart,
  handleScheduleEnd,
  register,
  control,
  watch,
  errors,
}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const { activeTM } = useContext(AppContext);
  const formData = watch();
  const { t } = useTranslation<$TSFixMe>();

  /*********************************************************
   *  Time off request policies and department
   **********************************************************/
  const [policies, setPolicies] = useState<Policy[] | null>([]);
  const [departments, setDepartments] = useState<Department[] | null>([]);

  const currentTimeOffRequestData: Partial<TimeOffRequest> | null = useMemo(() => {
    const formDataIsEmpty = Object.values(formData).every((v) => !v);
    if (formDataIsEmpty) return null;

    return {
      team_member: formData.employee?.value,
      employee_note: formData.employee_note,
      company_note: formData.company_note,
      department_id: timeOffRequest ? timeOffRequest?.department_id : activeTM?.department_id,
    };
  }, [formData]);

  useMemo(async () => {
    try {
      let response: (Policy[] & MiterError) | null = null;
      response = await MiterAPI.policies.search({
        filter: [
          { field: "company_id", type: "string", value: activeTM?.company._id },
          { field: "type", type: "string", value: "time_off_request" },
          { field: "archived", type: "boolean", value: false },
        ] as MiterFilterArray,
      });

      if (response?.error) {
        throw new Error(response?.error);
      }
      setPolicies(response);
    } catch (e) {
      console.log(e);
      setPolicies(null);
    }
  }, [timeOffRequest]);

  useMemo(async () => {
    try {
      const response = await MiterAPI.departments.search({
        filter: [
          { field: "company_id", type: "string", value: activeTM?.company._id },
          { field: "archived", type: "boolean", value: false },
        ] as MiterFilterArray,
      });

      if (response?.error) {
        throw new Error(response?.error);
      }
      setDepartments(response);
    } catch (e) {
      console.log(e);
      setDepartments(null);
    }
  }, [timeOffRequest]);

  const lookupPolicy = (id?: string | null) => {
    if (!id) return;

    return policies?.find((policy) => policy._id.toString() === id);
  };

  const lookupDepartment = (id?: string | null) => {
    if (!id) return;

    return departments?.find((department) => department._id.toString() === id);
  };

  const lookupTeam = (id?: string | null) => {
    if (id === activeTM?._id) return activeTM;
    return undefined;
  };

  const { isFieldVisible, isFieldRequired } = useTimeOffRequestPolicy({
    item: currentTimeOffRequestData,
    company: activeTM?.company,
    lookupPolicy,
    lookupDepartment,
    lookupTeam,
  });

  /*********************************************************
   *  Generate the options for the time off policy dropdown
   **********************************************************/
  const generateTimeOffPolicyOptions = () => {
    if (!activeTM) return [];

    const employee_policies = activeTM?.time_off.policies.map((policy) => policy.policy_id);
    const tenure = getTenure(activeTM.start_date);

    // Look through the companies time off policies and add the ones that the employee is enrolled in into the policy options array
    const policyOptions: (TimeOffPolicy & Option<string>)[] = timeOffPolicies
      .filter((policy) => {
        if (!employee_policies.includes(policy._id)) return false;

        const levelConfig = policy.levels.find((level) => level._id === policy.level_id);
        return !levelConfig?.min_tenure_for_requests || levelConfig?.min_tenure_for_requests <= tenure;
      })
      .map((policy) => {
        return { ...policy, label: policy.name, value: policy._id };
      });

    // If there are no policies active for this team member but there is a time off request with an old policy, add the old policy's information to the policy options
    if (policyOptions.length === 0 && timeOffRequest && timeOffRequest.time_off_policy) {
      const policy = timeOffRequest.time_off_policy;
      policyOptions.push({ ...policy, label: policy.name, value: policy._id });
    }

    // Return the policy options array
    return policyOptions;
  };

  // Set the editing mode to true if the mode is new or update
  const editing = mode === "new" || mode === "update";

  // Disable the employee / policy dropdowns if you are updating the time off request
  const disableDropdowns = mode !== "new";

  // Generate the options for the time off policies dropdown
  const timeOffPolicyOptions = generateTimeOffPolicyOptions();

  // Generate the options for the schedule type radio button
  const scheduleTypeOptions = [
    { label: t("Full Days"), value: "full_days" },
    { label: t("Partial Days"), value: "partial_days" },
  ];

  // Check if the time off request has a schedule date in the weekend
  const hasWeekendHours = timeOffRequest?.schedule?.some((day) => {
    return day.date.weekday === 6 || day.date.weekday === 7;
  });

  const renderCompanyNote = () => {
    if (mode !== "manage") return;

    return (
      isFieldVisible("company_note") && (
        <Formblock
          label={t("Company note")}
          type="paragraph"
          name="company_note"
          register={register(isFieldRequired("company_note") ? required : undefined)}
          className="modal time-off-request-notes"
          errors={errors}
          editing={editing}
          defaultValue={timeOffRequest ? timeOffRequest.company_note || "-" : "-"}
        />
      )
    );
  };

  const renderSubmittedAt = () => {
    if (!timeOffRequest || mode !== "manage") return;

    return (
      <Formblock
        label={t("Submitted on")}
        type="datetime"
        name="created_at"
        register={register}
        className="modal time-off-request-notes"
        errors={errors}
        editing={editing}
        defaultValue={timeOffRequest ? DateTime.fromSeconds(timeOffRequest.created_at) : undefined}
      />
    );
  };

  return (
    <div className={"modal-form-column"}>
      {editing ? (
        <Formblock
          label={t("Time Off Policy*")}
          labelInfo={t("Select the time off policy that you want to use for this request.")}
          type="select"
          control={control}
          name="time_off_policy"
          options={timeOffPolicyOptions}
          register={register(vals.required)}
          className="modal "
          defaultValue={timeOffRequest ? timeOffRequest.time_off_policy.value : undefined}
          errors={errors}
          editing={editing}
          requiredSelect={true}
          disabled={disableDropdowns}
        />
      ) : (
        <Formblock
          label={t("Time Off Policy*")}
          labelInfo={t("Select the time off policy that you want to use for this request.")}
          type="text"
          control={control}
          name="time_off_policy"
          register={register(vals.required)}
          className="modal "
          defaultString={timeOffRequest ? timeOffRequest.time_off_policy.label : undefined}
          errors={errors}
          editing={editing}
        />
      )}
      <div className={styles["time-off-request-dates"]}>
        <Formblock
          label={t("Start Date")}
          labelInfo={t("Start date for the time off request")}
          type="datetime"
          control={control}
          dateOnly={true}
          rules={vals.required}
          name="start_date"
          className="modal time-off-request-date"
          errors={errors}
          editing={editing}
          onChange={(dt: DateTime) => {
            handleScheduleStart(_.cloneDeep(dt));
          }}
          defaultValue={timeOffRequest ? timeOffRequest.start_date : null}
        />
        <Formblock
          label={t("End Date")}
          labelInfo={t("End date for the time off request")}
          type="datetime"
          name="end_date"
          control={control}
          dateOnly={true}
          rules={{
            validate: (end_date: DateTime | null) =>
              !end_date ? "This field is required" : vals.isValidEndDate(formData.start_date, end_date),
          }}
          className="modal time-off-request-date end-date"
          errors={errors}
          editing={editing}
          onChange={(dt: DateTime) => {
            handleScheduleEnd(_.cloneDeep(dt));
          }}
          defaultValue={timeOffRequest ? timeOffRequest.end_date : null}
        />
      </div>
      <Formblock
        label={t("Are you taking these fully off or partially?")}
        type="radio"
        name="schedule_type"
        register={register(vals.required)}
        className="modal time-off-request-radio"
        errors={errors}
        editing={editing}
        options={scheduleTypeOptions}
        defaultValue={timeOffRequest ? timeOffRequest.schedule_type : "full_days"}
      />
      <Formblock
        label={t("Include weekend hours?")}
        type="radio"
        name="include_weekends"
        register={register(vals.required)}
        className="modal time-off-request-radio"
        errors={errors}
        editing={editing}
        options={[
          { label: t("Yes"), value: "yes" },
          { label: t("No"), value: "no" },
        ]}
        defaultValue={hasWeekendHours ? "yes" : "no"}
      />
      {isFieldVisible("employee_note") && (
        <Formblock
          label={t("Time Off Reason")}
          type="paragraph"
          name="employee_note"
          register={register(isFieldRequired("employee_note") ? required : undefined)}
          className="modal time-off-request-notes"
          errors={errors}
          editing={editing}
          defaultValue={
            timeOffRequest ? timeOffRequest.employee_note || (mode === "manage" ? "-" : "") : null
          }
        />
      )}
      {renderCompanyNote()}
      {renderSubmittedAt()}
    </div>
  );
};

export default TimeOffRequestForm;
