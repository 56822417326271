import Notifier from "../..//utils/notifier";
import React, { useContext, useEffect } from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import { Navigate, useNavigate } from "react-router-dom";

import styles from "./Auth.module.css";
import { Loader } from "ui";
import AppContext from "../../contexts/app-context";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const { setUser, setTeamMembers, setActiveTM } = useContext(AppContext);

  useEffect(() => {
    try {
      localStorage.removeItem("authToken");
      setUser(undefined);
      setTeamMembers([]);
      setActiveTM(undefined);
      Notifier.success("You have successfully logged out!");
      navigate("/login", { replace: true });
    } catch (e: $TSFixMe) {
      navigate("/dashboard", { replace: true });
      Notifier.error(e.message);
    }
  }, []);

  if (!authToken) {
    Notifier.success("Your session is no longer valid!");
    return <Navigate to="/login" replace />;
  }
  return (
    <div className={styles["login-wrapper"]}>
      <div className={styles["login-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Logging out of Miter</h1>
            <p>Please wait as we redirect you.</p>
          </div>
          {<Loader />}
        </div>
      </div>
    </div>
  );
};

export default Logout;
