import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaFolder,
  FaFolderOpen,
  FaHome,
  FaMoneyCheckAlt,
  FaNotesMedical,
  FaQuestionCircle,
  FaUmbrellaBeach,
  FaUserCircle,
  FaFileSignature,
} from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { useTranslation } from "react-i18next";
import TeamMemberToggler from "../TeamMemberToggler";
import AppContext from "team-portal/contexts/app-context";
import { isActiveClaspCompany, isClaspMember } from "team-portal/utils/clasp-utils";
import { MiterAPI } from "team-portal/utils/miter";
import { Bank } from "phosphor-react";
import { useIsActive } from "team-portal/hooks/useIsActive";

const NavItems: React.FC<{ device: string }> = ({ device }) => {
  const { t } = useTranslation<$TSFixMe>();
  const { pathname } = useLocation();
  const { activeTM, onboardingChecklistItem, I9 } = useContext(AppContext);
  const [missingCertification, setMissingCertifications] = useState(false);
  const isActive = useIsActive();

  const activeView = pathname.split("/")[1] || "personal-info";

  const determineMissingCertifications = async () => {
    if (!activeTM) return;
    try {
      const res = await MiterAPI.certifications.forage({
        filter: [
          { field: "company_id", value: activeTM.company._id },
          { field: "team_member_id", value: activeTM._id },
          { field: "submitted", value: false },
        ],
      });
      if (res.data.length > 0) {
        setMissingCertifications(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    determineMissingCertifications();
  }, [activeTM]);

  if (!activeTM) return null;

  const noCheckData = !activeTM.check_tm;
  const remainingSteps = activeTM.check_tm ? activeTM.check_tm.onboard.remaining_steps : [];
  const noPaymentMethod = remainingSteps.includes("payment_method");
  const companyRequiresI9 = !!activeTM.company.settings.team?.onboarding?.enable_online_i9s;

  const onboarded =
    activeTM.onboarded &&
    (I9?.status === "complete" || !companyRequiresI9) &&
    (!onboardingChecklistItem ||
      (onboardingChecklistItem.custom_statuses?.every((item) => item.status === "complete") &&
        onboardingChecklistItem.statuses.every((item) => item.status === "complete")));
  const configuredTabs = activeTM?.company?.settings?.team?.enabled_portal_tabs;

  return (
    <div className="nav-items">
      {device === "desktop" && <div className="sidebar-spacer" />}
      <NavItem label={t("Dashboard")} path={"dashboard"} activeView={activeView} icon={<FaHome />} />
      <div className="sidebar-spacer" />

      {!onboarded && configuredTabs?.onboarding && (
        <NavItem
          label={t("Onboarding")}
          path={"onboarding"}
          alert={true}
          activeView={activeView}
          icon={<FaFolder />}
        />
      )}

      {activeTM.check_tm && (
        <NavItem label={t("Paystubs")} path={"paystubs"} activeView={activeView} icon={<FaMoneyCheckAlt />} />
      )}

      {configuredTabs?.payment_info && (
        <NavItem label={t("Payment Info")} path={"payment-info"} activeView={activeView} icon={<Bank />} />
      )}
      {configuredTabs?.time_off && isActive && (
        <NavItem label={t("Time Off")} path={"time-off"} activeView={activeView} icon={<FaUmbrellaBeach />} />
      )}

      {isClaspMember(activeTM) &&
        isActiveClaspCompany(activeTM.company) &&
        configuredTabs?.benefits_admin && (
          <NavItem
            label={t("Benefits")}
            path={"benefits-admin"}
            activeView={activeView}
            icon={<FaNotesMedical />}
          />
        )}
      <div className="sidebar-spacer" />

      {configuredTabs?.forms && (
        <NavItem label={t("Forms")} path={"forms"} activeView={activeView} icon={<IoIosPaper size={19} />} />
      )}

      {configuredTabs?.certifications && (
        <NavItem
          label={t("Certifications")}
          path={"certifications"}
          activeView={activeView}
          icon={<FaFileSignature size={19} style={{ marginLeft: 3 }} />}
          alert={missingCertification}
        />
      )}

      {configuredTabs?.documents && (
        <NavItem label={t("Documents")} path={"documents"} activeView={activeView} icon={<FaFolderOpen />} />
      )}

      {configuredTabs?.profile && (
        <NavItem
          label={t("Profile")}
          path={"profile"}
          alert={noCheckData || noPaymentMethod}
          activeView={activeView}
          icon={<FaUserCircle />}
        />
      )}
      <div className="sidebar-spacer" />
      {configuredTabs?.help && (
        <div>
          <a
            className="sidebar-menu-item "
            href={"mailto:support@miter.com"}
            style={{ textDecoration: "none" }}
          >
            <span className="sidebar-icon">
              <FaQuestionCircle />
            </span>
            <span className="sidebar-label">{t("Help")}</span>
          </a>
        </div>
      )}
      <div className="sidebar-spacer" />
      {device === "mobile" && <TeamMemberToggler />}
    </div>
  );
};

type NavItemProps = {
  label: string;
  alert?: $TSFixMe;
  path: string;
  activeView: string;
  icon: React.ReactNode;
  onClick?: () => void;
};

const NavItem: React.FC<NavItemProps> = ({ label, alert, path, activeView, icon, onClick }) => {
  const navigate = useNavigate();

  const handleClick = (clickedItem) => navigate("/" + clickedItem);

  return (
    <div
      onClick={onClick ? () => onClick() : () => handleClick(path)}
      className={activeView === path ? "sidebar-menu-item clicked" : "sidebar-menu-item "}
    >
      <span className="sidebar-icon">{icon}</span>
      <span className="sidebar-label">{label}</span>
      {alert && <img className="sidebar-img" style={{ height: 13, width: 13 }} src={"/alert.png"} />}
    </div>
  );
};

export default NavItems;
