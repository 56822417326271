import React, { useState, useEffect, FC } from "react";
import { useParams } from "react-router";
import AppContext from "team-portal/contexts/app-context";
import { Loader } from "ui";
import { MiterAPI } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";
import { openBlobThisTab } from "team-portal/utils/utils";
import { useTranslation } from "react-i18next";

type Params = {
  id: string;
};

const Paystub: FC = () => {
  /*********************************************************
   *  Call Hooks and Contexts
   **********************************************************/

  const { id: payrollId } = useParams<Params>();
  const { activeTM } = React.useContext(AppContext);
  const { t } = useTranslation<$TSFixMe>();

  /*********************************************************
   *  Initialize states
   **********************************************************/

  const [loading, setLoading] = useState(false);

  /*********************************************************
   *  Download paystub PDF in base64
   **********************************************************/
  const getPaystub = async () => {
    if (!activeTM || !payrollId) return;
    setLoading(true);
    try {
      let res;
      if (activeTM.company?.settings.payroll.use_miter_paystubs_and_checks) {
        res = await MiterAPI.team_member.miter_paystubs.retrieve(activeTM._id, [payrollId]);
      } else {
        res = await MiterAPI.team_member.paystubs.retrieve(activeTM._id, [payrollId]);
      }

      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      openBlobThisTab(blob);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(t("We are unable to get your paystub at this time. Please contact support."));
    }
    setLoading(false);
  };

  /*********************************************************
   *  Get the paystub for the specified payroll
   **********************************************************/
  useEffect(() => {
    getPaystub();
  }, []);

  if (loading) return <Loader />;
  return null;
};

export default Paystub;
