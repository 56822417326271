import React, { ReactNode, useState } from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import Input from "react-phone-number-input/input";
import ReactCodeInput from "react-code-input";

import { Loader } from "ui";
import Notifier from "team-portal/utils/notifier";
import { MiterAPI } from "team-portal/utils/miter";

import styles from "./Auth.module.css";
import { useContext } from "react";
import AppContext from "team-portal/contexts/app-context";
import { useNavigate, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { sleep, useQuery } from "miter-utils";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const redirect = useQuery().get("redirect");
  const hasAuthToken = !!localStorage.getItem("authToken");

  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);

  const [authCode, setAuthCode] = useState<string>("");
  const [methodID, setMethodID] = useState<string>("");

  const [authCodeSent, setAuthCodeSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);

  const { setUser, setTeamMembers, setOnboardLink, setActiveTM } = useContext(AppContext);

  useEffect(() => {
    if (authCode.length === 6) {
      verifyAuthCode();
    }
  }, [authCode]);

  const sendAuthCode = async (): Promise<void> => {
    setLoading(true);
    try {
      const formattedEmail = email.toLowerCase().trim();

      const res = showEmailForm
        ? await MiterAPI.users.sendEmailAuthCode(formattedEmail)
        : await MiterAPI.users.sendSmsAuthCode(phone);
      if (res.error) throw new Error(res.error);

      setAuthCodeSent(true);
      setMethodID(res.method_id);

      if (showEmailForm) {
        Notifier.success("Check your email for your auth code.");
      } else {
        Notifier.success("Check your phone for your auth code.");
      }
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.log("Error:", e);
    }
    setLoading(false);
  };

  const verifyAuthCode = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await MiterAPI.users.authenticate(methodID, authCode);
      if (res.error) throw new Error(res.error);
      if (!res.authToken || typeof res.authToken !== "string") throw new Error("Invalid auth token");
      localStorage.setItem("authToken", res.authToken);

      setUser(res.user);
      setTeamMembers(res.team_members);
      setActiveTM(res.tm);
      setOnboardLink(res.onboardLink);

      Notifier.success("You have successfully logged in!");
      navigate(redirect || "/dashboard", { replace: true });
    } catch (e: $TSFixMe) {
      setFailed(true);
      Notifier.error(e.message);
      await sleep(2000);
      navigate(0);
    }
    setLoading(false);
  };

  const handlePhoneSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    sendAuthCode();
  };

  const handleEmailSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    sendAuthCode();
  };

  const handleAuthCodeSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    verifyAuthCode();
  };

  const renderPhoneForm: React.ReactNode = (() => {
    let buttonNode: ReactNode;

    if (loading) {
      buttonNode = <Loader className="button auth" />;
    } else {
      buttonNode = "Submit";
    }

    return (
      <>
        <div className={styles["login-form"]}>
          <form onSubmit={handlePhoneSubmit}>
            <Input
              name="phone_number"
              className={styles["login-input"]}
              value={phone}
              defaultCountry="US"
              // @ts-expect-error Ignore the type error
              onChange={setPhone}
              placeholder={"Enter your phone number"}
              required
            />
            {
              <button type="submit" className={styles["login-submit"]} disabled={loading}>
                {buttonNode}
              </button>
            }
          </form>
        </div>
        <p className={styles["or"]}>or</p>
        <button onClick={() => setShowEmailForm(true)} className={styles["login-with-email"]}>
          Login with email
        </button>
      </>
    );
  })();

  const renderEmailForm: React.ReactNode = (() => {
    let buttonNode: ReactNode;

    if (loading) {
      buttonNode = <Loader className="button auth" />;
    } else {
      buttonNode = "Submit";
    }

    return (
      <>
        <div className={styles["login-form"]}>
          <form onSubmit={handleEmailSubmit}>
            <input
              name="email"
              type="email"
              className={styles["login-input"]}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"Enter your email"}
              required
            />
            {
              <button type="submit" className={styles["login-submit"]} disabled={loading}>
                {buttonNode}
              </button>
            }
          </form>
        </div>
        <p className={styles["or"]}>or</p>
        <button onClick={() => setShowEmailForm(false)} className={styles["login-with-phone"]}>
          Login with phone number
        </button>
      </>
    );
  })();

  const renderSendAuthCodeForm: React.ReactNode = (() => {
    if (showEmailForm) {
      return renderEmailForm;
    } else {
      return renderPhoneForm;
    }
  })();

  const renderSubmitAuthCodeForm: React.ReactNode = (() => {
    let buttonNode: ReactNode;

    if (loading) {
      buttonNode = <Loader className="button auth" />;
    } else {
      buttonNode = "Login";
    }

    return (
      <div className={styles["login-form"]}>
        <form onSubmit={handleAuthCodeSubmit}>
          <ReactCodeInput
            className={styles["react-code"]}
            inputMode="numeric"
            name="authCode"
            type="number"
            fields={6}
            onChange={setAuthCode}
          />
          {
            <button
              type="submit"
              className={styles["login-submit"]}
              disabled={failed || loading}
              style={{ marginTop: 25 }}
            >
              {buttonNode}
            </button>
          }
        </form>
      </div>
    );
  })();

  if (hasAuthToken) return <Navigate to="/dashboard" />;

  return (
    <div className={styles["login-wrapper"]}>
      <div className={styles["login-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Miter Team Portal</h1>
            <p>
              {!authCodeSent
                ? showEmailForm
                  ? "Enter your email below"
                  : "Enter your phone number below."
                : "Enter your 6 digit auth code below."}
            </p>
          </div>
          {!authCodeSent ? renderSendAuthCodeForm : renderSubmitAuthCodeForm}
        </div>
      </div>
    </div>
  );
};

export default Login;
