import { DateTime } from "luxon";
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { datesBetween } from "team-portal/utils/utils";
import { PreppedAggTimeOffRequest, Schedule } from "./TimeOffRequestModal";
import styles from "./TimeOffRequestSchedule.module.css";

/*********************************************************
 *  TimeOffRequestSchedule
 *
 *  This component is responsible for rendering the
 *  grandular day / hour scheduling form for a time off
 *  request.
 *
 *  Most of the state management for this form is done in
 *  the higher level TimeOffRequestModal component, but
 *  this component is responsible for maintaining the
 *  current page of the schedule (i.e. the input that are
 *  being displayed to the user)
 *
 *  The reason that the schedule state is being maintained
 *  at the higher level component is for simplicity.
 **********************************************************/

type Props = {
  mode?: "new" | "update" | "manage";
  timeOffRequest?: PreppedAggTimeOffRequest;
  schedule: Schedule[];
  scheduleStart?: DateTime;
  scheduleEnd?: DateTime;
  scheduleType: "full_days" | "partial_days";
  setScheduleStart: Dispatch<SetStateAction<DateTime | undefined>>;
  setScheduleEnd: Dispatch<SetStateAction<DateTime | undefined>>;
  setSchedule: Dispatch<SetStateAction<Schedule[]>>;
  includeWeekends?: boolean;
};

const TimeOffRequestSchedule: FC<Props> = ({
  mode,
  timeOffRequest,
  schedule,
  scheduleStart,
  scheduleEnd,
  scheduleType,
  setSchedule,
  includeWeekends,
}) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [schedulePage, setSchedulePage] = useState(0);

  /*********************************************************
   *  generateScheduleArray(start_date, end_date)
   *
   *  Generate the schedule array based on the start and
   *  end dates passed in from the TimeOffRequestForm and
   *  the based on the existing timeOffRequest's schedule.
   **********************************************************/
  const generateScheduleArray = (start_date: DateTime, end_date: DateTime) => {
    // Setup an array to hold either the passed in schedule or the old schedule.
    let localSchedule: Schedule[] = [];

    // If a time off request exists (i.e. if this is an update/manage form) and the timeOffRequest's schedule is not empty
    if (timeOffRequest && timeOffRequest.schedule.length > 0) {
      /*********************************************************
       * Set the local schedule array to the time off request's
       * schedule.
       *
       * Note: We do this so that we can preserve the old
       * schedule if the user cancels the form.
       **********************************************************/

      localSchedule = timeOffRequest.schedule;
    }

    // If there is an updated schedule, then we need to change the local schedule from the default schedule to the updated schedule.
    if (schedule.length > 0 && mode === "update") {
      localSchedule = schedule;
    }

    // If we are in either the update or manage mode and the schedule is empty (which should be impossible), return out of this function.
    if ((mode === "update" || mode === "manage") && !localSchedule.length) return;

    // Setup the schedule array of dates + hours that we will return
    const scheduleArray: Schedule[] = [];

    // Check if there is a start date and end date
    if (start_date && end_date) {
      // Call the utils function to generate an array of days between the state and end date
      const daysBetween = datesBetween(start_date.toJSDate(), end_date.toJSDate(), !!includeWeekends);

      // Loop through each day between the start and end date
      for (let i = 0; i < daysBetween.length; i++) {
        // Set the default hours for a day to 8 hours
        let hours = 8;

        // Search through the local schedule array to see if the current day is in the schedule
        // @ts-expect-error Weird type issue with DateTime
        const currentDay = localSchedule.find((item) => item.date.ts === daysBetween[i]!.ts);

        // If this day is already in the schedule, set the hours to the hours in the schedule as a default.
        if (currentDay) {
          hours = currentDay.hours;
        }

        // If the schedule type that is selected is a full days schedule, then override the hours to be 8 on all days.
        if (scheduleType === "full_days") {
          hours = 8;
        }

        // Add the update date / hours to the schedule array
        scheduleArray.push({
          date: daysBetween[i]!,
          hours: hours,
        });
      }
    }

    // Save the generated schedule array in state and reset the schedule page to the first page.
    setSchedule(scheduleArray);
    setSchedulePage(0);
  };

  // Basic state handler for changes to the schedule array.
  const handleScheduleInputChange = (e, day) => {
    // Get the hours from the input
    let hours = e.target.value;

    // Copy the schedule array into a new array
    const updatedSchedule = [...schedule];

    // Iterate through the schedule array and update the hours in the array based on the inputed hours
    for (const d of updatedSchedule) {
      if (d === day) {
        if (isNaN(hours)) {
          hours = 0;
        } else if (hours < 0) {
          hours = 0;
        } else if (hours > 8) {
          hours = 8;
        }
        day.hours = hours;
      }
    }

    // Save the updated schedule array in state
    setSchedule(updatedSchedule);
  };

  // Function to render the inputs for the schedule
  const renderScheduleForm = () => {
    // Get the schedule type from the form data or from the time off request or set it to partial days as a default
    const schedule_type = scheduleType || timeOffRequest?.schedule_type || "partial_days";

    // If there is not schedule start or schedule end or the schedule type is not partial days, return and don't display the schedule form.
    if (!scheduleStart || !scheduleEnd || schedule_type !== "partial_days") return <></>;

    // Establish the basic pagination for the schedule page
    const start = schedulePage * 7;
    const end = Math.min(start + 6, schedule.length - 1);
    const hasPrev = start > 0;
    const hasNext = end < schedule.length - 1;

    // Generate the visible schedule inputs based on the current page
    const visibleDays = schedule.slice(start, end + 1);

    // Generate the input fields for the schedule
    const scheduleInputs = visibleDays.map((day, index) => {
      // Convert the date to a readable string
      const date = day.date.toFormat("MMM dd");
      const hours = day.hours;

      return (
        <span key={"day-" + index} className={styles["schedule-input-container"]}>
          <span className={styles["schedule-day"]}>{date}</span>
          <input
            className={"form2-text " + styles["schedule-input"]}
            value={hours}
            onChange={(e) => handleScheduleInputChange(e, day)}
            min={0}
            max={8}
            disabled={mode === "manage"}
            required={true}
          ></input>
          <span className={styles["schedule-hours"]}> hrs</span>
        </span>
      );
    });

    return (
      <div className={styles["schedule-form"]}>
        <p className={styles["schedule-label"]}>Hours Breakdown</p>
        <div className={styles["schedule-inputs"]}>{scheduleInputs}</div>
        <button
          className={"button-1 " + (!hasPrev ? "inactive" : "")}
          onClick={() => setSchedulePage(schedulePage - 1)}
          disabled={!hasPrev}
          style={{ marginLeft: 0 }}
        >
          Previous
        </button>
        <button
          className={"button-1 " + (!hasNext ? "inactive" : "")}
          onClick={() => setSchedulePage(schedulePage + 1)}
          disabled={!hasNext}
        >
          Next
        </button>
      </div>
    );
  };

  /*********************************************************
   *  useEffect
   *
   *  Regenerate the schedule array each time the type of
   *  schedule, mode, start date, end date, or time off
   *  request changes.
   **********************************************************/

  useEffect(() => {
    if (!scheduleStart || !scheduleEnd) return;
    generateScheduleArray(scheduleStart, scheduleEnd);
  }, [scheduleType, scheduleStart, scheduleEnd, mode, JSON.stringify(timeOffRequest), includeWeekends]);

  // Return the form
  return renderScheduleForm();
};

export default TimeOffRequestSchedule;
