import React, { useState, useEffect, FC, useContext } from "react";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { MiterAPI } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";
import { Badge, ConfirmModal, ModalHeader, ModalFooter, Loader } from "ui";
import TimeOffRequestForm from "./TimeOffRequestForm";
import TimeOffRequestSummary from "./TimeOffRequestSummary";
import TimeOffRequestSchedule from "./TimeOffRequestSchedule";
import styles from "./TimeOffRequestModal.module.css";
import { AggregatedTimeOffRequest, TimeOffRequest } from "dashboard/miter";
import { TimeOffPolicyWithBalanceAndLevel } from "team-portal/pages/TimeOff";
import AppContext from "team-portal/contexts/app-context";
import { Assign } from "utility-types";
import { Option } from "ui/form/Input";
import { useTranslation } from "react-i18next";
import { BalanceEstimate } from "miter-utils/time-off";

/*********************************************************
 *  TimeOffRequestModal
 *
 *  This modal is used for creating and updating time off
 *  requests for a user
 **********************************************************/
type Props = {
  timeOffPolicies: TimeOffPolicyWithBalanceAndLevel[];
  timeOffRequestID?: string;
  onHide: () => void;
  onSubmit: () => void;
  reviewing: boolean;
  initialMode?: "new" | "update" | "manage";
};

export type Schedule = {
  date: DateTime;
  hours: number;
};

export type PreppedAggTimeOffRequest = Assign<
  AggregatedTimeOffRequest,
  {
    time_off_policy: AggregatedTimeOffRequest["time_off_policy"] & Option<string>;
    start_date: DateTime;
    end_date: DateTime;
    schedule_type: "full_days" | "partial_days";
    schedule: Schedule[];
  }
>;

const TimeOffRequestModal: FC<Props> = ({
  timeOffPolicies,
  timeOffRequestID,
  onHide,
  onSubmit,
  initialMode,
}) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const { activeTM } = useContext(AppContext);
  const { register, control, watch, errors, handleSubmit, setValue } = useForm();
  const formData = watch();
  const { t } = useTranslation<$TSFixMe>();

  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [timeOffRequest, setTimeOffRequest] = useState<PreppedAggTimeOffRequest>();

  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [balanceEstimate, setBalanceEstimate] = useState<BalanceEstimate>();

  // We have some extra states to keep track of the scheduling form
  const [schedule, setSchedule] = useState<Schedule[]>([]);
  const [scheduleStart, setScheduleStart] = useState<DateTime>();
  const [scheduleEnd, setScheduleEnd] = useState<DateTime>();

  // Keep track of the modal mode: "new", "update", "manage"
  const [mode, setMode] = useState<"new" | "update" | "manage" | undefined>(initialMode);

  useEffect(() => {
    formData.time_off_policy?.value && formData.start_date && getBalanceEstimate();
  }, [timeOffRequest, activeTM, formData.time_off_policy?.value, formData.start_date]);

  const getBalanceEstimate = async () => {
    try {
      const estimate = await MiterAPI.time_off.requests.getBalanceEstimate({
        requestStartDate: formData?.start_date?.toISO() || timeOffRequest?.start_date?.toISO(),
        teamMemberId: activeTM!._id,
        timeOffPolicyId: formData.time_off_policy?.value,
        timeOffRequestId: timeOffRequestID,
      });

      setBalanceEstimate(estimate);
    } catch (e: $TSFixMe) {
      Notifier.error("Error retrieving estimated balance");
      console.log(`Team member: ${activeTM!._id}, policy: ${formData.time_off_policy?.value}`);
      console.error(`Error retrieving estimated balance`, e);
    }
  };

  /*********************************************************
   *  Functions for reshaping data from the form and API
   **********************************************************/

  // Prepares the data that the form generates to be saved in the backend
  const prepareDataForBackend = (data): Partial<TimeOffRequest> | undefined => {
    if (!activeTM) return;
    const total_hours = buildTotalHours();

    // Make sure that the schedule doesn't have any empty inputs
    const hasEmptyHours = schedule.some(
      (item) => item.hours === undefined || item.hours === null || item.hours.toString().length === 0
    );

    let errMessage = "";
    if (hasEmptyHours) {
      errMessage = "Please make sure that all the hours input boxes are filled";
    } else if (!scheduleStart || !scheduleEnd) {
      errMessage = "Time off request must have start and end dates";
    }

    // Make sure the time off request doesn't go over the employee's balance
    const timeOffPolicy = timeOffPolicies.find((p) => p._id === data.time_off_policy.value);
    if (
      timeOffPolicy &&
      timeOffPolicy.levels.find((level) => level._id === timeOffPolicy.level_id)?.disable_negative_balances
    ) {
      const estimatedAccrual = balanceEstimate?.accrualProjection || 0;
      const estimatedUsage = balanceEstimate?.usageProjection || 0;
      const balance = timeOffPolicy?.balance || 0;
      const estimatedBalance = balance + estimatedAccrual - estimatedUsage;

      if (balance != null && total_hours > estimatedBalance) {
        errMessage = `This time off policy does not allow negative balances`;
      }
    }

    if (errMessage) {
      Notifier.error(errMessage);
      return;
    }
    return {
      company: activeTM!.company._id,
      employee: activeTM!._id,
      time_off_policy: data.time_off_policy ? data.time_off_policy.value : null,
      start_date: scheduleStart!.toISODate(),
      end_date: scheduleEnd!.toISODate(),
      total_hours: total_hours,
      status: "unapproved",
      schedule: schedule.map((item) => ({
        ...item,
        date: item.date.toISODate(),
      })),
      company_note: data.company_note,
      employee_note: data.employee_note,
      department_id: timeOffRequest ? timeOffRequest?.department_id : activeTM.department_id,
    };
  };

  // Prepares the time off request data from the backend to be populated in the form for updating
  const prepareDataForForm = (data: AggregatedTimeOffRequest) => {
    // Create the default value for the full/partial days radio button by checking if the request schedule has any hours that aren't equal to 8.
    const isFullDays = data.schedule.every((item) => item.hours === 8);

    // Prepare the schedule data for the form by turning all the ISO date strings into luxon DateTime objects
    const preparedSchedule: Schedule[] = data.schedule.map((item) => {
      return {
        date: DateTime.fromISO(item.date),
        hours: item.hours,
      };
    });

    // Prepopulate the requested time off request data into a new object
    const preparedData: PreppedAggTimeOffRequest = {
      ...data,
      time_off_policy: {
        ...data.time_off_policy,
        label: data.time_off_policy.name,
        value: data.time_off_policy._id,
      },
      start_date: DateTime.fromISO(data.start_date),
      end_date: DateTime.fromISO(data.end_date),
      schedule_type: isFullDays ? "full_days" : "partial_days",
      schedule: preparedSchedule,
    };

    // Set the non react hook form state objects with the prop data
    setScheduleStart(DateTime.fromISO(data.start_date));
    setScheduleEnd(DateTime.fromISO(data.end_date));
    setSchedule(preparedSchedule);

    return preparedData;
  };

  /*********************************************************
   *  Functions for pulling data such as employees and time
   *  off policies to setup the time off requests form.
   **********************************************************/

  // Get time off request for the modal based on the ID passed into the component
  const getTimeOffRequest = async () => {
    if (!timeOffRequestID) return;
    setLoading(true);
    try {
      const response = await MiterAPI.time_off.requests.retrieve(timeOffRequestID);
      if (response.error) {
        throw new Error(response.error);
      }

      const preparedData = prepareDataForForm(response);
      setTimeOffRequest(preparedData);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error fetching the time off request. We're looking into it.");
    }
    setLoading(false);
  };

  /*********************************************************
   *  Functions for CRUD'ing time off requests
   **********************************************************/

  // Creates the time off request
  const createTimeOffRequest = async (data) => {
    setLoading(true);
    try {
      const preppedData = prepareDataForBackend(data);
      if (preppedData) {
        const payload = { data: preppedData };
        const response = await MiterAPI.time_off.requests.create(payload);
        if (response.error) throw Error(response.error);

        Notifier.success("Created time off request.");
        onSubmit();
      }
    } catch (e: $TSFixMe) {
      console.error("Error creating time off request:", e);
      Notifier.error("There was an error creating the time off request. We're looking into it.");
    }
    setLoading(false);
  };

  // Updates the time off request
  const updateTimeOffRequest = async (data) => {
    setLoading(true);
    try {
      const preppedData = prepareDataForBackend(data);
      if (preppedData) {
        const payload = { data: preppedData };
        const response = await MiterAPI.time_off.requests.update(timeOffRequestID!, payload);
        if (response.error) throw Error(response.error);

        Notifier.success("Updated time off request.");
        onSubmit();
      }
    } catch (e: $TSFixMe) {
      console.error("Error updating time off request:", e);
      Notifier.error("There was an error updating the time off request. We're looking into it.");
    }
    setLoading(false);
  };

  // Deletes the time off request
  const deleteTimeOffRequest = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.time_off.requests.delete(timeOffRequestID!);
      if (response.error) {
        throw Error(response.error);
      }
      Notifier.success("Deleted time off request.");
      onSubmit();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the time off request. We're looking into it.");
    }
    setLoading(false);
    setShowDeleteConfirmation(false);
  };

  /*********************************************************
   *  Helper Functions
   **********************************************************/

  // Function used to either create or update a time off request
  const buildTimeOffRequest = async (data) => {
    if (mode === "update") {
      await updateTimeOffRequest(data);
    } else if (mode === "new") {
      await createTimeOffRequest(data);
    }
  };

  // Calculate the total hours of time off requested based on the schedule hours
  const buildTotalHours = () => {
    if (!schedule || schedule.length === 0) return 0;

    let hours = 0;
    schedule.forEach((day) => (hours += Number(day.hours) || 0));

    return hours;
  };

  // Cancel button action
  const onCancel = () => {
    if (mode === "update") {
      setMode("manage");
    } else {
      onHide();
    }
  };

  // Submit button action
  const onSubmitBtnClick = () => {
    if (mode === "update" || mode === "new") {
      handleSubmit(buildTimeOffRequest)();
    } else if (mode === "manage") {
      setMode("update");
    }
  };

  const showDelete = timeOffRequest && timeOffRequest.status === "unapproved";

  const hideSubmit = mode === "manage" && timeOffRequest && timeOffRequest.status !== "unapproved";

  // Check if the time off request data has loaded (if this is an update or manage mode)
  const loaded = !timeOffRequestID || (timeOffRequestID && timeOffRequest);

  // Show/Hide the delete confirmation button
  const displayDeleteConfirmation = () => setShowDeleteConfirmation(true);
  const hideDeleteConfirmation = () => setShowDeleteConfirmation(false);

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const modalTitle = (() => {
    if (mode === "new") {
      return t("Create Time Off Request");
    } else if (mode === "update") {
      return (
        <>
          <span className={styles["modal-title"]}>
            <span>{t("Update Time Off Request")}</span>
            {timeOffRequest && <Badge text={timeOffRequest.status} />}
          </span>
        </>
      );
    } else if (mode === "manage") {
      const text = timeOffRequest && timeOffRequest.status === "unapproved" ? "Manage" : "View";

      return (
        <>
          <span className={styles["modal-title"]}>
            <span>
              {text} {t("Time Off Request")}
            </span>
            {timeOffRequest && <Badge text={timeOffRequest.status} />}
          </span>
        </>
      );
    }
  })();

  const renderSummary = () => {
    // Set up the summary variables
    let balance, requested, updated, levelId;

    // Get the time off policy ID based on the formData
    let timeOffPolicy = formData.time_off_policy;

    // If there is no time off policy selected in the form but there is a time off request
    if (!timeOffPolicy && timeOffRequest && timeOffRequest.time_off_policy) {
      // Set the time off policy ID to be the time off policy ID of the existing time off request
      timeOffPolicy = timeOffRequest.time_off_policy;
    }

    // If there is an employee and time off policy selected
    if (activeTM && activeTM.time_off.policies && timeOffPolicy) {
      // Iterate through the employee's time off policies to find the current balance for selected time off policy
      activeTM.time_off.policies.forEach((policy) => {
        if (policy.policy_id === timeOffPolicy.value) {
          balance = policy.balance;
          levelId = policy.level_id;
        }
      });

      // Clean and round the balance
      balance = parseFloat((Math.round(balance * 100) / 100).toFixed(2));

      // Caculate the requested hours and save the updated hours
      requested = buildTotalHours();
      updated = balance - requested;

      // Clean and round the updated balance
      updated = parseFloat((Math.round(updated * 100) / 100).toFixed(2));
    }

    // Get request status
    const status = timeOffRequest ? timeOffRequest.status : "new";
    const unlimited = timeOffPolicy
      ? timeOffPolicy.levels.find((level) => level._id === levelId)?.unlimited
      : false;

    const fullTimeOffPolicy = timeOffPolicies.find((policy) => policy._id === timeOffPolicy?.value);
    if (!fullTimeOffPolicy) return;

    const startDateTime = formData.start_date || timeOffRequest?.start_date;

    if (!startDateTime) return;

    return (
      <TimeOffRequestSummary
        balance={balance}
        requested={requested}
        updated={updated}
        status={status}
        unlimited={unlimited}
        startDate={startDateTime}
        balanceEstimate={balanceEstimate}
      />
    );
  };

  const renderForm = () => {
    if (loaded) {
      const includeWeekends = formData.hasOwnProperty("include_weekends")
        ? formData.include_weekends === "yes"
        : timeOffRequest?.schedule?.some((day) => {
            return day.date.weekday === 6 || day.date.weekday === 7;
          });

      return (
        <>
          <TimeOffRequestForm
            timeOffRequest={timeOffRequest}
            timeOffPolicies={timeOffPolicies}
            handleScheduleStart={setScheduleStart}
            handleScheduleEnd={setScheduleEnd}
            register={register}
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            handleSubmit={handleSubmit}
            mode={mode}
          />
          <div className={"modal-form-column"}>
            <TimeOffRequestSchedule
              mode={mode}
              timeOffRequest={timeOffRequest}
              schedule={schedule}
              scheduleStart={scheduleStart}
              scheduleEnd={scheduleEnd}
              scheduleType={formData.schedule_type}
              setScheduleStart={setScheduleStart}
              setScheduleEnd={setScheduleEnd}
              setSchedule={setSchedule}
              includeWeekends={includeWeekends}
            />
            {renderSummary()}
          </div>
        </>
      );
    } else {
      return <Loader />;
    }
  };

  const renderSubmitText = () => {
    if (mode === "manage") {
      return "Edit";
    } else if (mode === "update") {
      return "Update";
    } else {
      return "Save";
    }
  };

  /*********************************************************
   *  useEffect functions
   **********************************************************/

  useEffect(() => {
    if (!timeOffRequestID) {
      setMode("new");
    } else {
      setMode("manage");
    }
  }, []);

  // This use effect resets the schedule form when the mode is changed to "manage". This usually happens when the cancel button is clicked.
  useEffect(() => {
    if (mode === "manage" && timeOffRequest) {
      setScheduleStart(timeOffRequest.start_date);
      setScheduleEnd(timeOffRequest.end_date);
    }
  }, [mode]);

  useEffect(() => {
    if (timeOffRequestID) {
      getTimeOffRequest();
    }
  }, [timeOffRequestID, timeOffPolicies.length]);

  return (
    <div className="modal-background">
      {/* <ClickAwayListener onClickAway={onHide}> */}
      <div className={"modal-wrapper form two-column"}>
        <ModalHeader heading={modalTitle} onHide={onHide} className={"time-off-request"} />
        <div className={"modal-body form two-column "}>{renderForm()}</div>
        {loaded && (
          <ModalFooter
            loading={loading}
            className={"form"}
            cancelText={"Cancel"}
            hideCancel={mode !== "update"}
            showDelete={showDelete}
            onDelete={displayDeleteConfirmation}
            onCancel={onCancel}
            submitText={renderSubmitText()}
            onSubmit={onSubmitBtnClick}
            hideSubmit={hideSubmit}
          />
        )}
      </div>
      {/* </ClickAwayListener> */}
      {showDeleteConfirmation && (
        <ConfirmModal
          title={t("Delete Time Off Request")}
          body={t("Are you sure you want to delete this time off request?")}
          onYes={deleteTimeOffRequest}
          onNo={hideDeleteConfirmation}
        />
      )}
    </div>
  );
};
export default TimeOffRequestModal;
