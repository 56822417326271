import AppContext from "team-portal/contexts/app-context";
import { useContext, useEffect, useState } from "react";
import { MiterAPI } from "team-portal/utils/miter";
import { Loader, Notifier } from "ui";
import { ClaspReact } from "clasp-components";
import axios from "axios";

const BenefitsAdmin = (): JSX.Element | null => {
  const { activeTM: teamMember } = useContext(AppContext);
  const [componentUrl, setComponentUrl] = useState<string | null>(null);

  const claspMemberId = teamMember?.clasp_id;

  const getComponentUrl = async () => {
    if (!claspMemberId) return;
    try {
      const response = await MiterAPI.clasp.get_member_component_url(teamMember?._id);
      if (response.error) throw new Error(response.error);
      setComponentUrl(response.url);
    } catch (e) {
      console.log("Clasp error: ", e);
      Notifier.error(
        "Failed to authenticate for Miter benefits administration. Please try again or reach out to support if the issue persists."
      );
    }
  };

  useEffect(() => {
    getComponentUrl();
  }, [claspMemberId]);

  if (!claspMemberId) {
    return null;
  } else if (!componentUrl) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      <ClaspReact
        className="height-100"
        link={componentUrl}
        onError={(error) => {
          if (axios.isAxiosError(error) && error.response?.status !== 403) {
            console.error("Clasp component error: ", error.response?.data);
          }
          Notifier.error("There was an error loading the benefits administration page.");
        }}
      />
    </div>
  );
};

export default BenefitsAdmin;
