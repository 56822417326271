import React, { useState, useEffect, FC } from "react";
import { useParams } from "react-router";

import { Loader } from "ui";
import { MiterAPI } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";

type Params = {
  id: string;
};

const File: FC = () => {
  /*********************************************************
   *  Call Hooks and Contexts
   **********************************************************/

  const { id } = useParams<Params>();

  /*********************************************************
   *  Initialize states
   **********************************************************/

  const [loading, setLoading] = useState(false);

  /*********************************************************
   *  useEffect functions
   *  - redirect to paymentws if activeTM isn't an employee
   *  - get the taxForm for the specified payroll
   **********************************************************/

  useEffect(() => {
    loadDocument();
  }, []);

  /*********************************************************
   *  Download taxForm PDF in base64
   **********************************************************/
  const loadDocument = async () => {
    if (!id) return;

    setLoading(true);
    try {
      if (!id) throw new Error("File ID is required.");

      const responseData = await MiterAPI.files.retrieve(id);
      if (responseData.error) throw responseData.error;

      window.location.replace(responseData.url);
    } catch (e) {
      console.error(e);
      Notifier.error("We are unable to get the file at this time.");
    }
    setLoading(false);
  };

  if (loading) return <Loader />;
  return null;
};

export default File;
