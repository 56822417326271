import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/routes/ProtectedRoute";

import Dashboard from "./pages/Dashboard";
import Paystubs from "./pages/Paystubs";
import Paystub from "./pages/Paystub";
import Profile from "./pages/Profile";
import Logout from "./pages/auth/Logout";
import TimeOff from "./pages/TimeOff";
import Login from "./pages/auth/Login";
import NotFound from "./components/NotFound";
import Onboarding from "./pages/Onboarding";
import File from "./pages/File";
import Documents from "./pages/Documents";
import TaxForm from "./pages/TaxForm";
import Forms from "./pages/forms/Forms";
import FormSubmissions from "./pages/forms/FormSubmissions";
import { OnboardingChecklist } from "./pages/OnboardingChecklist";
import BenefitsAdmin from "./pages/BenefitsAdmin";
import Certifications from "./pages/certifications/Certifications";
import PaymentInfo from "./pages/PaymentInfo";
import Impersonate from "./pages/auth/Impersonate";

const PortalRoutes: React.FC<{
  enabledPortalTabs?: {
    time_off: boolean;
    profile: boolean;
    documents: boolean;
    forms: boolean;
    onboarding: boolean;
    benefits_admin?: boolean;
  };
  companyId?: string;
}> = (props) => {
  // keep company id for future feature flags here
  const { enabledPortalTabs, companyId: _companyId } = props;
  const routeList: { path: string; component: React.FC; enabled: boolean }[] = [
    { path: "/profile", component: Profile, enabled: enabledPortalTabs?.profile || false },
    { path: "/dashboard", component: Dashboard, enabled: true },
    { path: "/onboarding", component: Onboarding, enabled: enabledPortalTabs?.onboarding || false },
    {
      path: "/benefits-admin",
      component: BenefitsAdmin,
      enabled: enabledPortalTabs?.benefits_admin || false,
    },
    { path: "/paystubs/:id", component: Paystub, enabled: true },
    { path: "/paystubs", component: Paystubs, enabled: true },
    { path: "/time-off/:view?", component: TimeOff, enabled: enabledPortalTabs?.time_off || false },
    { path: "/files/:id", component: File, enabled: enabledPortalTabs?.documents || false },
    { path: "/documents", component: Documents, enabled: enabledPortalTabs?.documents || false },
    { path: "/tax-forms/:id", component: TaxForm, enabled: enabledPortalTabs?.forms || false },
    { path: "/forms", component: Forms, enabled: enabledPortalTabs?.forms || false },
    { path: "/forms/:id", component: FormSubmissions, enabled: enabledPortalTabs?.forms || false },
    {
      path: "/onboarding/checklist",
      component: OnboardingChecklist,
      enabled: true,
    },
    {
      path: "/certifications",
      component: Certifications,
      enabled: true,
    },
    {
      path: "/payment-info",
      component: PaymentInfo,
      enabled: true,
    },
  ];

  const protectedRoutes = routeList.map((item) => {
    const element = (
      <ProtectedRoute accessible={item.enabled}>
        <item.component />
      </ProtectedRoute>
    );
    return <Route path={item.path} key={item.path} element={element} />;
  });

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/impersonate" element={<Impersonate />} />
      {protectedRoutes}
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};

export default PortalRoutes;
