import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "Please setup your SSN before you try to add your withholdings.": {
    en: "Please setup your SSN before you try to add your withholdings.",
    es: "Por favor configure su SSN antes de intentar agregar sus retenciones.",
  },
  "Please setup your address before you try to add your withholdings.": {
    en: "Please setup your address before you try to add your withholdings.",
    es: "Por favor configure su dirección antes de intentar agregar sus retenciones.",
  },
  "Please ask your company administrator to add your start date to your profile before you try to add your withholdings.":
    {
      en: "Please ask your company administrator to add your start date to your profile before you try to add your withholdings.",
      es: "Pídale al administrador de su empresa que agregue su fecha de inicio a su perfil antes de intentar agregar sus retenciones.",
    },
  "Please setup your SSN before you try to add your payment method.": {
    en: "Please setup your SSN before you try to add your payment method.",
    es: "Por favor configure su SSN antes de intentar agregar su método de pago.",
  },
  "There was an error opening this page. Please contact support": {
    en: "There was an error opening this page. Please contact support",
    es: "Se produjo un error al abrir esta página. Por favor, póngase en contacto con el soporte",
  },
  "Profile successfully updated.": {
    en: "Profile successfully updated.",
    es: "Perfil actualizado con éxito.",
  },
  "There was an error updating your profile. Please contact support.": {
    en: "There was an error updating your profile. Please contact support.",
    es: "Se produjo un error al actualizar su perfil. Por favor, póngase en contacto con el soporte.",
  },
  "There was an error starting your I9. Please contact support.": {
    en: "There was an error starting your I9. Please contact support.",
    es: "Se produjo un error al iniciar su I9. Por favor, póngase en contacto con el soporte.",
  },
  "Please setup your SSN before you try to start your I-9.": {
    en: "Please setup your SSN before you try to start your I-9.",
    es: "Por favor configure su SSN antes de intentar iniciar su I-9.",
  },
  "Error downloading I-9. Please contact support.": {
    en: "Error downloading I-9. Please contact support.",
    es: "Error al descargar I-9. Por favor, póngase en contacto con el soporte.",
  },
  "Setup your personal profile and payroll information.": {
    en: "Setup your personal profile and payroll information.",
    es: "Configure su perfil personal e información de nómina.",
  },
  "Edit your withholdings": {
    en: "Edit your withholdings",
    es: "Editar sus retenciones",
  },
  "Setup withholdings": {
    en: "Setup withholdings",
    es: "Configurar retenciones",
  },
  "Edit your payment method": {
    en: "Edit your payment method",
    es: "Editar su método de pago",
  },
  "Edit your personal info": {
    en: "Edit your personal info",
    es: "Editar su información personal",
  },
  "Add your personal info": {
    en: "Add your personal info",
    es: "Añadir su información personal",
  },
  "Setup payment info": {
    en: "Setup payment info",
    es: "Configurar información de pago",
  },
  "Review and sign your company's onboarding documents.": {
    en: "Review and sign your company's onboarding documents.",
    es: "Revise y firme los documentos de integración de su empresa.",
  },
  "Fill out your I-9 and upload the relevant documents": {
    en: "Fill out your I-9 and upload the relevant documents",
    es: "Complete su I-9 y cargue los documentos relevantes",
  },
  "Please complete your personal info before starting the I-9": {
    en: "Please complete your personal info before starting the I-9",
    es: "Complete su información personal antes de comenzar el I-9",
  },
  "Additional information requested by your company.": {
    en: "Additional information requested by your company.",
    es: "Información adicional solicitada por su empresa.",
  },
  "Add emergency contacts to be notified in case of an emergency.": {
    en: "Add emergency contacts to be notified in case of an emergency.",
    es: "Agregue contactos de emergencia para ser notificado en caso de una emergencia.",
  },
  "Edit your emergency contacts": {
    en: "Edit your emergency contacts",
    es: "Editar sus contactos de emergencia",
  },
  "Add your emergency contacts": {
    en: "Add your emergency contacts",
    es: "Añadir sus contactos de emergencia",
  },
  "Add your basic info": {
    en: "Add your basic info",
    es: "Añadir su información básica",
  },
  "SSN is required to run payroll": {
    en: "SSN is required to run payroll",
    es: "Se requiere SSN para ejecutar la nómina",
  },
  "By checking this box, I agree to receive SMS's from Miter. You can text STOP to unsubscribe at any time.":
    {
      en: "By checking this box, I agree to receive SMS's from Miter. You can text STOP to unsubscribe at any time.",
      es: "Al marcar esta casilla, acepto recibir SMS de Miter. Puede enviar un mensaje de texto STOP para cancelar la suscripción en cualquier momento.",
    },
  "I wish to receive paperless W2 statements and related tax information from Miter.": {
    en: "I wish to receive paperless W2 statements and related tax information from Miter.",
    es: "Deseo recibir declaraciones W2 sin papel e información fiscal relacionada de Miter.",
  },
  "I wish to receive paperless 1099 statements and related tax information from Miter.": {
    en: "I wish to receive paperless 1099 statements and related tax information from Miter.",
    es: "Deseo recibir declaraciones 1099 sin papel e información fiscal relacionada de Miter.",
  },
  "You have submitted your I-9. Please remember to bring the original versions of the documents you used to complete this form, on your first day of work.":
    {
      en: "You have submitted your I-9. Please remember to bring the original versions of the documents you used to complete this form, on your first day of work.",
      es: "Ha enviado su I-9. Recuerde traer las versiones originales de los documentos que utilizó para completar este formulario, el primer día de trabajo.",
    },
  "Emergency Contacts": {
    en: "Emergency Contacts",
    es: "Contactos de emergencia",
  },
  "Setup payment method": {
    en: "Setup payment method",
    es: "Configurar método de pago",
  },
  "Payment method updated": {
    en: "Payment method updated",
    es: "Método de pago actualizado",
  },
  "Failed to update payment method": {
    en: "Failed to update payment method",
    es: "Error al actualizar el método de pago",
  },
  "Update payment method": {
    en: "Update payment method",
    es: "Actualizar método de pago",
  },
};

export default translation;
