import { Assign } from "utility-types";
import { ColDef } from "ag-grid-community";
import { AggregatedJob, AggregatedTeamMember, CustomField, CustomFieldValue } from "dashboard/miter";

type CustomFieldValueAgGridCell = { [custom_field_id: string]: CustomFieldValue["value"] };
type CustomFieldExportRow = { [custom_field_id: string]: CustomFieldValue };

/****************************************************************************************
 * Render a custom field's value
 *****************************************************************************************/
export const renderCustomFieldValue = (cf: CustomField, cfv?: CustomFieldValue): string => {
  if (!cfv) return "-";

  const value = cfv.value;

  if (cf.type === "checkbox") {
    if (value !== undefined && value !== null && (value as unknown as boolean) === false) {
      return "No";
    } else if (cfv) {
      return "Yes";
    }
  }

  return (value as unknown as string) || "-";
};

/****************************************************************************************
 * Build AgGrid columns from custom fields
 *****************************************************************************************/
export const buildAgGridColumns = (customFields: CustomField[]): ColDef[] => {
  const columns = customFields.map(buildAgGridColumn);
  return columns;
};

export const buildAgGridCell = (customFieldValue: CustomFieldValue): CustomFieldValueAgGridCell => {
  return {
    [customFieldValue.custom_field_id]: customFieldValue.value,
  };
};

export const buildAgGridRow = (customFieldValues: CustomFieldValue[]): $TSFixMe => {
  return customFieldValues.reduce((row, cfv) => {
    row[cfv.custom_field_id] = cfv.value;
    return row;
  }, {});
};

export const buildExportRow = (customFieldValues: CustomFieldValue[]): CustomFieldExportRow => {
  return customFieldValues.reduce((row, cfv) => {
    row[cfv.custom_field_id] = cfv;
    return row;
  }, {});
};

type CustomFieldParent = AggregatedJob | AggregatedTeamMember;
type CustomFieldExportRowData = Assign<
  CustomFieldParent,
  {
    custom_field_values: CustomFieldExportRow;
  }
>;

export const buildExportData = (data: CustomFieldParent[]): CustomFieldExportRowData[] => {
  return data.map((item) => {
    const exportable: CustomFieldExportRowData = { ...item, custom_field_values: {} };

    // Turn custom field array into an object
    if (exportable.custom_field_values?.length) {
      exportable.custom_field_values = buildExportRow(item.custom_field_values);
    }

    return exportable;
  });
};

const buildAggFunc = (cf: CustomField): string | null => {
  if (cf.type === "number") {
    return "sum";
  }

  return null;
};

const buildAgGridColumn = (cf: CustomField): ColDef => {
  const aggFunc = buildAggFunc(cf);
  const enableRowGroup = cf.type === "select" || cf.type === "checkbox" || cf.type === "text";

  const colDef: ColDef = {
    field: cf._id,
    headerName: cf.name + " (Custom)",
    initialHide: true,
    enableRowGroup,
    enableValue: cf.type === "number",
    sortable: true,
    filter: true,
  };

  // Need to make this a separate function or it breaks
  if (aggFunc) colDef.aggFunc = aggFunc;

  return colDef;
};
