import Notifier from "../..//utils/notifier";
import React, { useContext, useEffect, useState } from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import { useNavigate } from "react-router-dom";
import styles from "./Auth.module.css";
import { Loader } from "ui";
import { Helmet } from "react-helmet";
import { MiterAPI } from "team-portal/utils/miter";
import { sleep, useQuery } from "miter-utils";
import AppContext from "team-portal/contexts/app-context";

const Impersonate: React.FC = () => {
  const { setUser, setTeamMembers, setOnboardLink, setActiveTM } = useContext(AppContext);

  const navigate = useNavigate();
  const user = useQuery().get("user");
  const authToken = useQuery().get("auth_token");

  const [failed, setFailed] = useState<boolean>(false);

  useEffect((): void => {
    if (!user || !authToken) {
      Notifier.error("Invalid impersonation link");

      setUser(undefined);
      setTeamMembers([]);
      setActiveTM(undefined);
      localStorage.removeItem("authToken");

      navigate("/login", { replace: true });
      return;
    }

    authenticateUser(user, authToken);
  }, [user, authToken]);

  const authenticateUser = async (user: string, authToken: string): Promise<void> => {
    try {
      const res = await MiterAPI.mitos.impersonate_user(user, authToken);

      if (res.error) throw new Error(res.error);
      if (!res.authToken || typeof res.authToken !== "string") throw new Error("Invalid auth token");

      localStorage.setItem("authToken", res.authToken);
      await sleep(1);

      setUser(res.user);
      setTeamMembers(res.team_members);
      setActiveTM(res.tm);
      setOnboardLink(res.onboardLink);

      navigate("/", { replace: true });
    } catch (e: $TSFixMe) {
      setFailed(true);
      Notifier.error(e.message);
      navigate("/login", { replace: true });
    }
  };

  return (
    <div className={styles["login-wrapper"]}>
      <Helmet>
        <title>Impersonate | Miter</title>
      </Helmet>
      <div className={styles["login-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Impersonating into Miter</h1>
            <p>Please wait as we redirect you.</p>
          </div>
          {!failed && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Impersonate;
