import React, { useState, useEffect, useContext } from "react";
import { Badge, Table } from "ui";
import { Loader } from "ui";
import { MiterAPI } from "team-portal/utils/miter";
import AppContext from "team-portal/contexts/app-context";
import Notifier from "team-portal/utils/notifier";
import { DateTime } from "luxon";
import { usdString } from "ui/utils";
import { formatDate } from "team-portal/utils/utils";
import { ColumnConfigs } from "ui/table/types";
import { TablePayment } from "../../../backend/utils/aggregations/payrollAggregations";
import { useNavigate } from "react-router-dom";
import { FringesPaystubModal } from "./FringesPaystubModal";
import { useTranslation } from "react-i18next";
import EmptyState from "ui/empty-state/EmptyState";

type DownloadButtonProps = {
  payment: TablePayment;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ payment }) => {
  const { activeTM, companyHasPrgs } = useContext(AppContext);
  const { t } = useTranslation<$TSFixMe>();
  const navigate = useNavigate();
  const [emailLoading, setEmailLoading] = useState(false);
  const [showFringesModal, setShowFringesModal] = useState(false);
  if (!activeTM) return null;
  const tm_id = activeTM._id.toString();

  const handleViewClick = async () => {
    // window.open(window.location.origin + "/paystubs/" + payment.payroll_id);
    navigate("/paystubs/" + payment.payroll_id);
  };

  const handleEmailClick = async () => {
    setEmailLoading(true);

    let response;
    if (activeTM.company?.settings.payroll.use_miter_paystubs_and_checks) {
      response = await MiterAPI.team_member.miter_paystubs.email(tm_id, payment.payroll_id);
    } else {
      response = await MiterAPI.team_member.paystubs.email(tm_id, payment.check_payroll_id);
    }

    if (response.error === "no_email") {
      Notifier.error(
        t("Your account does not have an email listed. Please provide one on your profile page.")
      );
    } else if (!response.success) {
      console.error("Could not email paystub due to unsuccessful response:", JSON.stringify(response));
      Notifier.error(t("There was an error emailing the paystub. We're looking into it!"));
    } else {
      Notifier.success(t("Paystub emailed successfully."));
    }

    setEmailLoading(false);
  };
  return (
    <div className="flex" style={{ minWidth: 75 }}>
      <span className={"blue-link"} onClick={handleViewClick}>
        View
      </span>
      <div style={{ width: 20 }}></div>
      <span className={emailLoading ? "blue-link inactive" : "blue-link"} onClick={handleEmailClick}>
        Email
      </span>
      {companyHasPrgs && (
        <>
          <div style={{ width: 20 }}></div>
          <span className={"blue-link"} onClick={() => setShowFringesModal(true)}>
            See fringes
          </span>
        </>
      )}
      {showFringesModal && (
        <FringesPaystubModal
          payrollId={payment.payroll_id}
          paydayString={payment.payday}
          hide={() => setShowFringesModal(false)}
        />
      )}
    </div>
  );
};

type PaystubData = {
  _id: string;
  pay_period: string;
  payday_formatted: string;
  net_pay_formatted: string;
  status_badge: JSX.Element;
  download_button: JSX.Element;
};

const Paystubs: React.FC = () => {
  const { activeTM } = useContext(AppContext);
  const [paystubs, setPaystubs] = useState<PaystubData[]>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation<$TSFixMe>();

  const columnConfig: ColumnConfigs = [
    {
      field: "pay_period",
      label: t("PAY PERIOD"),
      type: "string",
    },
    {
      field: "payday_formatted",
      label: t("PAYDAY"),
      type: "string",
    },
    {
      field: "net_pay_formatted",
      label: t("NET PAY"),
      type: "string",
    },
    {
      field: "status_badge",
      label: t("STATUS"),
      type: "component",
    },
    {
      field: "download_button",
      label: t("ACTIONS"),
      type: "component",
    },
  ];

  const createStatusBadge = (payment: TablePayment) => {
    return (
      <div className="flex">
        <Badge table className="no-margin" text={payment.status?.replace("_", " ")} />
        {payment.is_void && <Badge text="void" color="light-gray" />}
      </div>
    );
  };

  /*********************************************************
   *  Download paystubs
   **********************************************************/

  const getPaystubs = async () => {
    setLoading(true);
    try {
      const res = await MiterAPI.team_member.payments.all(activeTM!._id);
      if (res.error) throw new Error(res.error);

      const payments = res.payments;
      const cleanedPaystubs = payments.map((p) => {
        return {
          _id: p.payroll_id,
          pay_period: formatDate(p.period_start, p.period_end, true),
          payday_formatted: DateTime.fromISO(p.payday).toFormat("DD"),
          net_pay_formatted: usdString(p.net_pay),
          status_badge: createStatusBadge(p),
          download_button: <DownloadButton payment={p} />,
        };
      });
      setPaystubs(cleanedPaystubs);
    } catch (e) {
      console.error(e);
      Notifier.error(t("There was an error fetching paystubs. We're looking into it!"));
    }
    setLoading(false);
  };

  const buildPaystubsTable = () => {
    if (loading) return <></>;

    if (!paystubs || paystubs.length === 0) {
      return (
        <EmptyState title={t("You don't have any paystubs yet.")} description={t("Check back later!")} />
      );
    } else {
      return (
        <Table
          columns={columnConfig}
          data={paystubs}
          hideSelect={true}
          selectedRows={[]}
          hideTableVerticalSpacer
          hideFooterButtons={!paystubs || paystubs.length < 10}
          hideFooterText={!paystubs || paystubs.length < 10}
        />
      );
    }
  };

  const buildPaystubsView = () => {
    return (
      <div>
        <h1 className="view-title">{t("Paystubs")}</h1>
        <div className="paystubs-table-container">
          {loading && <Loader />}
          {buildPaystubsTable()}
        </div>
      </div>
    );
  };

  useEffect(() => {
    getPaystubs();
  }, []);

  return buildPaystubsView();
};

export default Paystubs;
