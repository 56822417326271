import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "Bank Accounts": {
    en: "Bank Accounts",
    es: "Cuentas Bancarias",
  },
  "Add bank account": {
    en: "Add bank account",
    es: "Agregar cuenta bancaria",
  },
  "Connect with Plaid": {
    en: "Connect with Plaid",
    es: "Conectar con Plaid",
  },
  "Or add manually": {
    en: "Or add manually",
    es: "O agregar manualmente",
  },
  "Bank account created": {
    en: "Bank account created",
    es: "Cuenta bancaria creada",
  },
  "Could not create bank account": {
    en: "Could not create bank account",
    es: "No se pudo crear la cuenta bancaria",
  },
  "Could not connect account to Miter.": {
    en: "Could not connect account to Miter.",
    es: "No se pudo conectar la cuenta a Miter.",
  },
  "Manage Bank Account": {
    en: "Manage Bank Account",
    es: "Administrar cuenta bancaria",
  },
  "Account Name": {
    en: "Account Name",
    es: "Nombre de la cuenta",
  },
  "Account number": {
    en: "Account number",
    es: "Número de cuenta",
  },
  "Routing number": {
    en: "Routing number",
    es: "Número de ruta",
  },
  "View full account number": {
    en: "View full account number",
    es: "Ver número de cuenta completo",
  },
  Hide: {
    en: "Hide",
    es: "Esconder",
  },
  Type: {
    en: "Type",
    es: "Tipo",
  },
  checking: {
    en: "checking",
    es: "corriente",
  },
  savings: {
    en: "savings",
    es: "ahorros",
  },

  "Could not show full account number": {
    en: "Could not show full account number",
    es: "No se pudo mostrar el número de cuenta completo",
  },
  // ACH reimbursements
  "Bank account is now used for ACH reimbursements.": {
    en: "Bank account is now used for ACH reimbursements.",
    es: "La cuenta bancaria ahora se utiliza para reembolsos ACH.",
  },
  "Could not use bank account for ACH reimbursements. Contact an administrator for help.": {
    en: "Could not use bank account for ACH reimbursements. Contact an administrator for help.",
    es: "No se pudo utilizar la cuenta bancaria para reembolsos ACH. Póngase en contacto con un administrador para obtener ayuda.",
  },
  "Use for ACH reimbursements": {
    en: "Use for ACH reimbursements",
    es: "Usar para reembolsos ACH",
  },
  "Used for ACH reimbursements?": {
    en: "Used for ACH reimbursements?",
    es: "¿Usado para reembolsos ACH?",
  },
  "Direct Deposit Distribution": {
    en: "Direct Deposit Distribution",
    es: "Distribución de Depósito Directo",
  },
  "Distribute your paycheck by percentage or amount between bank accounts. Drag and drop each account to change the order in which funds will be distributed.":
    {
      en: "Distribute your paycheck by percentage or amount between bank accounts. Drag and drop each account to change the order in which funds will be distributed.",
      es: "Distribuya su cheque de pago por porcentaje o cantidad entre cuentas bancarias. Arrastre y suelte cada cuenta para cambiar el orden en el que se distribuirán los fondos.",
    },
  "Split By:": {
    en: "Split By:",
    es: "Dividir por:",
  },
  Split: {
    en: "Split",
    es: "Dividir",
  },
  "You can only add as many splits as you have valid bank accounts.": {
    en: "You can only add as many splits as you have valid bank accounts.",
    es: "Solo puedes agregar tantas divisiones como cuentas bancarias válidas tengas.",
  },
  Amount: {
    en: "Amount",
    es: "Cantidad",
  },
  Percentage: {
    en: "Percentage",
    es: "Porcentaje",
  },
  Priority: {
    en: "Priority",
    es: "Prioridad",
  },
  "Select bank account": {
    en: "Select bank account",
    es: "Seleccionar cuenta bancaria",
  },
  "Deposit up to": {
    en: "Deposit up to",
    es: "Depositar hasta",
  },
  "Deposit remainder of paycheck": {
    en: "Deposit remainder of paycheck",
    es: "Depositar el resto del cheque de pago",
  },
  "Successfully updated your paycheck split. This will take into effect in 1-2 pay periods.": {
    en: "Successfully updated your paycheck split. This will take into effect in 1-2 pay periods.",
    es: "Se ha actualizado correctamente la división de su cheque de pago. Esto se aplicará en 1-2 períodos de pago.",
  },
  "Could not update your paycheck split.": {
    en: "Could not update your paycheck split.",
    es: "No se pudo actualizar la división de su cheque de pago.",
  },
  "Bank accounts and your paycheck split preferences have moved to the new Payment Info tab! Click on this banner to navigate there.":
    {
      en: "Bank accounts and your paycheck split preferences have moved to the new Payment Info tab! Click on this banner to navigate there.",
      es: "¡Las cuentas bancarias y sus preferencias de división de cheque de pago se han trasladado a la nueva pestaña de Información de Pago! Haga clic en este banner para navegar allí.",
    },
  "Payment method preference (direct deposit vs paper check) has not been set.": {
    en: "Payment method preference (direct deposit vs paper check) has not been set.",
    es: "La preferencia de método de pago (depósito directo vs cheque de papel) no se ha establecido.",
  },
  "Direct deposit error: bank account has not been added or the existing one is invalid. Add a new bank account and update the direct deposit information.":
    {
      en: "Direct deposit error: bank account has not been added or the existing one is invalid. Add a new bank account and update the direct deposit information.",
      es: "Error de depósito directo: no se ha agregado una cuenta bancaria o la existente es inválida. Agregue una nueva cuenta bancaria y actualice la información de depósito directo.",
    },
  "Direct deposit verification": {
    en: "Direct deposit verification",
    es: "Verificación de depósito directo",
  },
};

export default translation;
