import { DateTime } from "luxon";

export const formatDate = (start: string, end?: string, year = false): string => {
  if (!start) return "";

  const startDt = DateTime.fromISO(start);
  const daysTillStart = Math.abs(startDt.diffNow("days").days);
  const showStartYear = daysTillStart > 180 || year ? ", yyyy" : "";
  let startFormat = "MMM d";

  if (!end) {
    return startDt.toFormat(startFormat + showStartYear);
  }

  const endDt = DateTime.fromISO(end);
  const daysTillEnd = Math.abs(endDt.diffNow("days").days);
  let endFormat = daysTillEnd > 180 || showStartYear ? ", yyyy" : "";

  if (startDt.year === endDt.year) {
    endFormat = (startDt.month === endDt.month ? "d" : "MMM d") + endFormat;
  } else {
    startFormat = endFormat = "MMM d, yyyy";
  }
  return startDt.toFormat(startFormat) + " - " + endDt.toFormat(endFormat);
};

const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];
export const stateOptions = states.map((state) => {
  return { value: state.abbreviation, label: state.abbreviation };
});

export const addressToString = (addressObj: $TSFixMe, oneLiner?: boolean): string => {
  return (
    (addressObj.postal_name || "").trim() +
    (oneLiner ? ", " : "\n") +
    (addressObj.line1 || "").trim() +
    (oneLiner ? " " : "\n") +
    (addressObj.line2 ? (addressObj.line2 || "").trim() + (oneLiner ? " " : "\n") : "") +
    (
      (addressObj.city || "") +
      ", " +
      (addressObj.state?.value || addressObj.state || "") +
      " " +
      (addressObj.postal_code || "")
    ).trim()
  ).trim();
};

export const truncate = (str: string, n: number): string => {
  if (str.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
};

export const capitalize = (s: string): string => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// Funky little function to get the dates between (inclusive) a start and end date
export const datesBetween = (
  start: string | number | Date,
  end: Date,
  includeWeekends: boolean
): DateTime[] => {
  const arr: DateTime[] = [];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    if (!includeWeekends && (dt.getDay() === 0 || dt.getDay() === 6)) {
      continue;
    }

    arr.push(DateTime.fromJSDate(new Date(dt)));
  }
  return arr;
};

export const extensionToMimeType = (extension: string): string | undefined => {
  if (!extension) return;

  const types = {
    aac: "audio/aac",
    abw: "application/x-abiword",
    ai: "application/postscript",
    arc: "application/octet-stream",
    avi: "video/x-msvideo",
    azw: "application/vnd.amazon.ebook",
    bin: "application/octet-stream",
    bz: "application/x-bzip",
    bz2: "application/x-bzip2",
    csh: "application/x-csh",
    css: "text/css",
    csv: "text/csv",
    doc: "application/msword",
    dll: "application/octet-stream",
    eot: "application/vnd.ms-fontobject",
    epub: "application/epub+zip",
    gif: "image/gif",
    htm: "text/html",
    html: "text/html",
    ico: "image/x-icon",
    ics: "text/calendar",
    jar: "application/java-archive",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    js: "application/javascript",
    json: "application/json",
    mid: "audio/midi",
    midi: "audio/midi",
    mp2: "audio/mpeg",
    mp3: "audio/mpeg",
    mp4: "video/mp4",
    mpa: "video/mpeg",
    mpe: "video/mpeg",
    mpeg: "video/mpeg",
    mpkg: "application/vnd.apple.installer+xml",
    odp: "application/vnd.oasis.opendocument.presentation",
    ods: "application/vnd.oasis.opendocument.spreadsheet",
    odt: "application/vnd.oasis.opendocument.text",
    oga: "audio/ogg",
    ogv: "video/ogg",
    ogx: "application/ogg",
    otf: "font/otf",
    png: "image/png",
    pdf: "application/pdf",
    ppt: "application/vnd.ms-powerpoint",
    rar: "application/x-rar-compressed",
    rtf: "application/rtf",
    sh: "application/x-sh",
    svg: "image/svg+xml",
    swf: "application/x-shockwave-flash",
    tar: "application/x-tar",
    tif: "image/tiff",
    tiff: "image/tiff",
    ts: "application/typescript",
    ttf: "font/ttf",
    txt: "text/plain",
    vsd: "application/vnd.visio",
    wav: "audio/x-wav",
    weba: "audio/webm",
    webm: "video/webm",
    webp: "image/webp",
    woff: "font/woff",
    woff2: "font/woff2",
    xhtml: "application/xhtml+xml",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.ms-excel",
    xlsx_OLD: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xml: "application/xml",
    xul: "application/vnd.mozilla.xul+xml",
    zip: "application/zip",
    "3gp": "video/3gpp",
    "3gp_DOES_NOT_CONTAIN_VIDEO": "audio/3gpp",
    "3gp2": "video/3gpp2",
    "3gp2_DOES_NOT_CONTAIN_VIDEO": "audio/3gpp2",
    "7z": "application/x-7z-compressed",
  };

  return types[extension.toLowerCase()];
};

export const truncateFilename = (initial: string, maxLength: number): string => {
  const split = initial.split(".");
  if (split.length === 0 || split.length === 1) return initial;

  let filename = split[0];
  const extension = split[1];

  if (filename!.length > maxLength) {
    filename = filename!.substring(0, maxLength) + "...";
  }

  return filename + "." + extension;
};

export const openBlobThisTab = (blob: Blob): void => {
  const url = URL.createObjectURL(blob);
  window.location.replace(url);
  // DO NOT REVOKE THE URL OR ELSE THIS BREAKS FOR SAFARI
};
