import React, { useContext, useEffect, useMemo, useState } from "react";
import { MiterAPI, MiterFilterArray } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";
import TimeOffRequestModal from "./modals/TimeOffRequestModal";
import { DateTime } from "luxon";
import { TableV2 } from "ui";
import { TimeOffPolicyWithBalanceAndLevel } from "team-portal/pages/TimeOff";
import { AggregatedTimeOffRequest } from "dashboard/miter";
import AppContext from "team-portal/contexts/app-context";
import { Plus } from "phosphor-react";
import { ColumnConfig, TableActionLink, TableTogglerConfig } from "ui/table-v2/Table";
import { useTranslation } from "react-i18next";

export type RequestTableEntry = AggregatedTimeOffRequest & {
  dates: string;
  total_hours_string: string;
  policy_name: string;
  employee_name: string;
  submitted_on: string;
};

type Props = {
  timeOffPolicies: TimeOffPolicyWithBalanceAndLevel[];
};

const TimeOffRequestsTable: React.FC<Props> = ({ timeOffPolicies }) => {
  const { setModalOpen, activeTM } = useContext(AppContext);
  const { t } = useTranslation<$TSFixMe>();
  const [timeOffRequests, setTimeOffRequests] = useState<RequestTableEntry[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [activeTimeOffRequest, setActiveTimeOffRequest] = useState<RequestTableEntry | null>(null);

  /*********************************************************
   *  Functions for fetching, updating, and cleaning data
   **********************************************************/
  const getTimeOffRequests = async () => {
    if (!activeTM) return;
    try {
      const filters = [
        { field: "company", type: "string", value: activeTM.company._id },
        { field: "employee._id", type: "_id", value: activeTM._id },
      ];

      const response = await MiterAPI.time_off.requests.retrieve_many(filters as MiterFilterArray);

      if (response.error) {
        throw Error(response.error);
      }

      const cleanedTimeOffRequests = cleanTimeOffRequests(response);
      setTimeOffRequests(cleanedTimeOffRequests);
    } catch (e) {
      console.log("Error", e);
      // @ts-expect-error Error cleanup
      Notifier.error(e.message);
    }
  };

  /*********************************************************
   *  Handler functions for the modal and table
   **********************************************************/

  const handleModalSubmit = () => {
    getTimeOffRequests();
    setShowModal(false);
    setModalOpen(false);
    setActiveTimeOffRequest(null);
  };

  const handleModalHide = () => {
    setShowModal(false);
    setModalOpen(false);
    setActiveTimeOffRequest(null);
  };

  const handleRowClick = (row) => {
    setActiveTimeOffRequest(row);
    setShowModal(true);
    setModalOpen(true);
  };

  /*********************************************************
    Config buttons for the table
  **********************************************************/
  const renderModal = () => {
    return (
      <TimeOffRequestModal
        onHide={handleModalHide}
        timeOffRequestID={activeTimeOffRequest ? activeTimeOffRequest._id : undefined}
        onSubmit={handleModalSubmit}
        reviewing={activeTimeOffRequest ? true : false}
        timeOffPolicies={timeOffPolicies.filter((p) => !!p.level_id)}
      />
    );
  };

  useEffect(() => {
    getTimeOffRequests();
  }, []);

  /*********************************************************
    Config buttons for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: t("New request"),
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        action: () => setShowModal(true),
        className: "button-2",
        important: true,
      },
    ],
    []
  );

  const columns: ColumnConfig<RequestTableEntry>[] = useMemo(
    () => [
      {
        field: "policy_name",
        headerName: "Policy",
        dataType: "string",
      },
      {
        field: "dates",
        headerName: "Dates",
        dataType: "date",
        isDateRange: true,
        includeDateYear: true,
        minWidth: 200,
      },
      {
        field: "total_hours_string",
        headerName: "Hours",
        dataType: "string",
      },
      {
        field: "status",
        headerName: "Status",
        dataType: "string",
        displayType: "badge",
        colors: { unapproved: "yellow", approved: "blue", denied: "red", paid: "green" },
      },
      {
        field: "submitted_on",
        headerName: "Submitted on",
        dataType: "date",
      },
    ],
    [status]
  );

  /*********************************************************
      Toggler configs
    **********************************************************/
  const togglerConfig: TableTogglerConfig<AggregatedTimeOffRequest> = {
    config: [
      { path: "unapproved", label: t("Unapproved") },
      { path: "approved", label: t("Approved") },
      { path: "processing", label: t("Processing") },
      { path: "paid", label: t("Paid") },
      { path: "denied", label: t("Denied") },
      { path: "all", label: t("All") },
    ],
    field: "status",
    secondary: true,
  };

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/
  const renderTable = () => {
    return (
      <TableV2
        id="time-off-requests-table"
        resource="time off requests"
        columns={columns}
        data={timeOffRequests}
        toggler={togglerConfig}
        staticActions={staticActions}
        onClick={handleRowClick}
      />
    );
  };

  return (
    <>
      {showModal && renderModal()}
      <div className="time-off-requests">
        <div className="vertical-spacer-small" />
        {renderTable()}
      </div>
    </>
  );
};

export const cleanTimeOffRequests = (data: AggregatedTimeOffRequest[]): RequestTableEntry[] => {
  return data.map((req) => {
    return {
      ...req,
      employee_name: req.employee.full_name,
      policy_name: req.time_off_policy?.name,
      dates: req.start_date + " " + req.end_date,
      total_hours_string: req.total_hours + " hours",
      submitted_on: DateTime.fromSeconds(req.created_at).toISODate(),
    };
  });
};

export default TimeOffRequestsTable;
