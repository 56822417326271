import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "team-portal/contexts/app-context";
import { AggregatedFormRow, formatFormRow } from "team-portal/utils/forms";
import { MiterAPI } from "team-portal/utils/miter";
import { Notifier, TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";

const columns: ColumnConfig<AggregatedFormRow>[] = [
  {
    headerName: "Name",
    field: "name",
    dataType: "string",
  },
  {
    headerName: "Complete",
    field: "complete",
    dataType: "boolean",
  },
];

const FormsTable: FC = () => {
  const { activeTM } = useContext(AppContext);
  const [forms, setForms] = useState<AggregatedFormRow[]>();
  const navigate = useNavigate();
  useEffect(() => {
    const asyncFunction = async () => {
      try {
        if (!activeTM) throw new Error("No team member found. Please refresh the page.");
        const res = await MiterAPI.team_member.forms({ id: activeTM._id });
        setForms(formatFormRow(res));
      } catch (e) {
        console.error(e);
        Notifier.error("There was an error fetching forms. We're looking into it.");
      }
    };
    asyncFunction();
  }, []);

  const handleRowClick = (row: AggregatedFormRow) => {
    navigate(`/forms/${row._id}`);
  };

  return (
    <div>
      <TableV2 id={"forms-table"} resource="forms" data={forms} columns={columns} onClick={handleRowClick} />
    </div>
  );
};

export default FormsTable;
