import React, { useState, useEffect, useMemo } from "react";

import AppContext from "team-portal/contexts/app-context";
import { addressToString } from "team-portal/utils/utils";

import { Formblock, Label, Loader, Notifier } from "ui";
import EditProfileModal from "team-portal/components/profile/EditProfileModal";
import { DateTime } from "luxon";
import { useCheckComponent } from "team-portal/utils/check";
import { CheckCircle, WarningCircle } from "phosphor-react";
import { CustomField } from "dashboard/miter";
import { MiterAPI } from "team-portal/utils/miter";
import { renderCustomFieldValue } from "team-portal/utils/custom-fields";
import CustomFieldValueModal from "team-portal/components/custom-fields/CustomFieldValuesModal";
import { EmergencyContactsForm } from "team-portal/components/EmergencyContactsForm";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getCustomFieldTypeForFormBlock } from "miter-utils";
import { TeamMemberProfilePicture } from "miter-components";
import { UpdatePaymentMethodAndWithholdingsModal } from "miter-components/onboarding/UpdatePaymentMethodAndWithholdingsModal";
import { CheckContractor, CheckEmployee, CheckTM } from "../../../backend/utils/check/check-types";

const Profile: React.FC = () => {
  const { control } = useForm();
  const { t } = useTranslation<$TSFixMe>();
  const { activeTM, fetchUserData, I9 } = React.useContext(AppContext);
  const check_tm: CheckTM | undefined = activeTM!.check_tm;

  const { renderComponent, loadingComponent } = useCheckComponent();

  const isEmployee = activeTM!.employment_type === "employee";
  const address = activeTM?.address;
  const mailingAddress = activeTM?.mailing_address || address;
  const teamSettings = activeTM?.company.settings?.team;

  const enabledDemographicQuestions = useMemo(
    () => activeTM?.company?.settings?.team?.enabled_demographic_questions || {},
    [activeTM]
  );

  const paymentMethodLookup = {
    direct_deposit: t("Direct deposit"),
    manual: t("Paper check"),
  };
  const [editing, setEditing] = useState(false);
  const [isMissingPaymentMethod, setIsMissingPaymentMethod] = useState(false);
  const [isMissingPersonalInfo, setIsMissingPersonalInfo] = useState(false);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [editingCustomFields, setEditingCustomFields] = useState(false);
  const [editingEmergencyContacts, setEditingEmergencyContacts] = useState(false);
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] = useState(false);
  const [downloadingI9, setDownloadingI9] = useState(false);

  const remainingOnboardSteps = activeTM?.check_tm?.onboard?.remaining_steps || [];

  const withholdingsNeedAttention =
    remainingOnboardSteps.some((s) => s === "withholdings" || s === "ssn") ||
    (activeTM?.employment_type === "employee" && !(activeTM?.check_tm as CheckEmployee)?.residence);

  const determineMissingInfo = async () => {
    const remaining_steps = activeTM?.check_tm?.onboard?.remaining_steps || [];
    if (remaining_steps.includes("payment_method")) {
      setIsMissingPaymentMethod(true);
    } else {
      setIsMissingPaymentMethod(false);
    }
    if (
      isEmployee &&
      teamSettings?.enable_demographic_reporting &&
      (!activeTM?.demographics?.gender || !activeTM.demographics?.ethnicity)
    ) {
      setIsMissingPersonalInfo(true);
    } else {
      setIsMissingPersonalInfo(false);
    }
  };

  const getCustomFields = async () => {
    if (!activeTM) return;
    try {
      const filter = [{ field: "company_id", value: activeTM.company._id }];
      const response = await MiterAPI.custom_fields.search(filter);
      if (response.error) throw new Error(response.error);

      const accessibleCustomFields = response.filter((cf) => {
        return cf.parent_type === "team_member" && cf.team_member_access && cf.team_member_access !== "none";
      });

      setCustomFields(accessibleCustomFields);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDownloadI9 = async () => {
    if (!activeTM || !I9) return;
    setDownloadingI9(true);
    try {
      const res = await MiterAPI.i_9s.retrieve_pdf(I9._id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      saveAs(blob, `I-9_${activeTM?.full_name}.pdf`);
    } catch (e: $TSFixMe) {
      Notifier.error(t("Error downloading I-9. Please contact support."));
      console.error("Error downloading I-9", e);
    }
    setDownloadingI9(false);
  };

  const handleSaveCustomFields = () => {
    fetchUserData();
    setEditingCustomFields(false);
  };

  const saveTM = async (data) => {
    if (!activeTM) return;
    try {
      const response = await MiterAPI.team_member.update(activeTM._id, data);
      if (response.error) throw new Error(response.error);

      await fetchUserData();

      Notifier.success("Updated your profile!");
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error(e.message);
    }
  };

  const renderLanguageSettings = () => {
    if (!activeTM) return;
    return (
      <>
        <div className="flex" style={{ marginBottom: 20, marginTop: 75 }}>
          <div className="page-heading">{t("Language")}</div>
        </div>
        <div></div>
        <Formblock
          type="select"
          label={t("Language")}
          name="language"
          defaultValue={activeTM?.language || "en"}
          editing={true}
          options={[
            { label: "English", value: "en" },
            { label: "Español", value: "es" },
          ]}
          onChange={async (e) => {
            saveTM({ language: e.value });
          }}
          control={control}
        />
      </>
    );
  };

  const renderEmploymentInformation = () => {
    if (!activeTM) return;
    return (
      <>
        <div className="flex" style={{ marginTop: 75, marginBottom: 20 }}>
          <div className="page-heading">{t("Employment info")}</div>
        </div>
        <Formblock
          type="text"
          label={t("Kiosk PIN")}
          name="kiosk_pin"
          defaultValue={activeTM?.kiosk_pin || "-"}
          editing={false}
          className="team-portal-custom-field"
        />
      </>
    );
  };
  const renderCustomFields = () => {
    if (!activeTM || !customFields.length) return;

    const elements = customFields.map((cf) => {
      const cfv = activeTM?.custom_field_values.find((cfv) => cfv.custom_field_id === cf._id);
      const value = renderCustomFieldValue(cf, cfv);
      const type = getCustomFieldTypeForFormBlock(cf);

      return (
        <Formblock
          key={cf._id}
          type={type as $TSFixMe}
          label={cf.name}
          labelInfo={cf.description}
          name={cf._id}
          defaultValue={value}
          editing={false}
          locked={true}
          className="team-portal-custom-field"
          // Add select options if select field
          {...(cf.type === "select" && { options: cf.options?.map((o) => ({ label: o, value: o })) })}
        />
      );
    });

    return (
      <>
        <div className="flex" style={{ justifyContent: "space-between", marginTop: 75, marginBottom: 20 }}>
          <div className="page-heading">{t("Custom fields")}</div>
          <div className="text-link" onClick={() => setEditingCustomFields(!editing)}>
            Edit
          </div>
        </div>
        <div>{elements}</div>
        {editingCustomFields && activeTM && (
          <CustomFieldValueModal
            parentID={activeTM?._id}
            parentType={"team_member"}
            defaultValues={activeTM?.custom_field_values || []}
            customFields={customFields}
            onFinish={() => handleSaveCustomFields()}
            onHide={() => setEditingCustomFields(false)}
          />
        )}
      </>
    );
  };

  const renderPaymentInfo = () => {
    if (!activeTM?.check_tm) return;

    // don't show if payment info tab is disabled
    if (activeTM?.company?.settings?.team?.enabled_portal_tabs?.payment_info === false) return null;

    return (
      <div>
        <div className="page-heading-wrapper">
          <div className="page-heading">
            {t("Payment method") +
              (activeTM?.employment_type === "employee" ? " " + t("& withholdings") : "")}
          </div>
          {isMissingPaymentMethod && (
            <img className="mobile-menu-alert" style={{ height: 13 }} src={"/alert.png"} />
          )}
          <div className="flex-1"></div>
          {loadingComponent ? (
            <Loader className="small-text" />
          ) : (
            <div className="text-link" onClick={() => setIsPaymentMethodModalOpen(true)}>
              {t("Edit")}
            </div>
          )}
        </div>

        <div>
          <Formblock
            type="text"
            locked={true}
            defaultValue={paymentMethodLookup[check_tm?.payment_method_preference ?? ""] || "Missing"}
            label={t("Payment method")}
          />
          <div style={{ height: 10 }}></div>
          {activeTM.employment_type === "employee" && (
            <>
              <div style={{ height: 10 }}></div>
              <Formblock type="text" locked={true} label={t("Withholdings")}>
                {withholdingsNeedAttention ? (
                  <WarningCircle weight="fill" color="#b60d0c" />
                ) : (
                  <CheckCircle weight="fill" color="#00b300" />
                )}
              </Formblock>
            </>
          )}
          <div style={{ height: 10 }}></div>

          {activeTM.employment_type === "employee" ? (
            <Formblock
              type="text"
              locked={true}
              defaultValue={(check_tm as CheckEmployee).w2_electronic_consent_provided ? "Yes" : "No"}
              label={t("Paperless W-2s")}
            />
          ) : (
            <Formblock
              type="text"
              locked={true}
              defaultValue={
                (check_tm as CheckContractor)["1099_nec_electronic_consent_provided"] ? "Yes" : "No"
              }
              label={t("Paperless 1099s")}
            />
          )}
        </div>
      </div>
    );
  };

  const renderEmergencyContacts = () => {
    const contacts = activeTM?.emergency_contacts?.length ? activeTM.emergency_contacts : [...Array(2)];

    return (
      <>
        <div style={{ height: 60 }}></div>
        <div className="page-heading-wrapper">
          <div className="page-heading">{t("Emergency contacts")}</div> <div className="flex-1"></div>
          <div className="text-link" onClick={() => setEditingEmergencyContacts(!editingEmergencyContacts)}>
            {editing ? "Cancel" : "Edit"}
          </div>
        </div>
        {contacts.map((contact, i) => {
          return (
            <div key={i} style={{ marginBottom: 20 }}>
              <h2 style={{ fontSize: 16, marginTop: 0, fontWeight: "bolder" }}>Contact #{i + 1}</h2>
              <Formblock type="text" locked={true} defaultValue={contact?.name || "-"} label={t("Name")} />
              <Formblock
                type="text"
                locked={true}
                defaultValue={contact?.relationship || "-"}
                label={t("Relationship")}
              />
              <Formblock type="text" locked={true} defaultValue={contact?.phone || "-"} label={t("Phone")} />
            </div>
          );
        })}

        {editingEmergencyContacts && activeTM && (
          <EmergencyContactsForm onHide={() => setEditingEmergencyContacts(false)} />
        )}
        {isPaymentMethodModalOpen && activeTM && (
          <UpdatePaymentMethodAndWithholdingsModal
            teamMember={activeTM}
            onClose={() => {
              setIsPaymentMethodModalOpen(false);
              fetchUserData();
            }}
            isProfile={true}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    determineMissingInfo();
    getCustomFields();
  }, [activeTM]);

  return (
    <div>
      <h1 className="view-title">{t("Profile")}</h1>

      <div className="profile-wrapper">
        <div className="profile-data">
          {activeTM && (
            <div style={{ marginBottom: 25, marginTop: -15 }}>
              <TeamMemberProfilePicture
                teamMember={activeTM}
                size={120}
                refetchTeam={async () => {
                  await fetchUserData();
                }}
              />
            </div>
          )}
          <div className="page-heading-wrapper">
            <div className="page-heading">{t("Personal info")}</div>
            {(isMissingPersonalInfo || !activeTM?.ssn_last_four) && (
              <img className="mobile-menu-alert" style={{ height: 13 }} src={"/alert.png"} />
            )}
            <div className="flex-1"></div>
            <div className="text-link" onClick={() => setEditing(!editing)}>
              {editing ? t("Cancel") : t("Edit")}
            </div>
          </div>
          {editing && <EditProfileModal hide={() => setEditing(false)} />}
          <div>
            <Formblock type="text" locked={true} defaultValue={activeTM?.email} label={t("Personal email")} />
            <Formblock
              type="text"
              locked={true}
              defaultValue={activeTM?.phone || "-"}
              label={t("Phone number")}
            />

            {isEmployee && (
              <div>
                <div style={{ height: 10 }}></div>
                <Formblock
                  type="text"
                  locked={true}
                  defaultValue={activeTM?.dob ? DateTime.fromISO(activeTM.dob).toFormat("DD") : "-"}
                  label={t("Date of birth")}
                />
              </div>
            )}
            <div style={{ height: 10 }}></div>
            <Formblock
              type="text"
              locked={true}
              defaultValue={address ? addressToString(address) : "-"}
              label={isEmployee ? t("Residence") : t("Address")}
            />
            <div style={{ height: 30 }}></div>
            <Formblock
              type="text"
              locked={true}
              defaultValue={mailingAddress ? addressToString(mailingAddress) : "-"}
              label={t("Mailing Address")}
            />
            <div style={{ height: 30 }}></div>

            <Formblock
              type="text"
              locked={true}
              defaultValue={activeTM?.start_date ? DateTime.fromISO(activeTM.start_date).toFormat("DD") : "-"}
              label={"Start date"}
            />
            <div style={{ height: 30 }}></div>

            {
              <Formblock
                type="text"
                locked={true}
                defaultValue={activeTM?.ssn_last_four ? "XXX-XX-" + activeTM?.ssn_last_four : "-"}
                label="SSN"
              />
            }
            <div style={{ height: 15 }}></div>
            {isEmployee && teamSettings?.enable_demographic_reporting && (
              <>
                {enabledDemographicQuestions["ethnicity"] && (
                  <Formblock
                    type="text"
                    locked={true}
                    defaultValue={activeTM?.demographics?.ethnicity || "-"}
                    label={t("Race/ethnicity")}
                    style={{ marginBottom: 15 }}
                  />
                )}
                {enabledDemographicQuestions["gender"] && (
                  <Formblock
                    type="text"
                    locked={true}
                    defaultValue={activeTM?.demographics?.gender || "-"}
                    label={t("Gender")}
                  />
                )}{" "}
                {enabledDemographicQuestions["veteran_status"] && (
                  <Formblock
                    type="text"
                    locked={true}
                    defaultValue={activeTM?.demographics?.veteran_status || "-"}
                    label={t("Veteran Status")}
                  />
                )}{" "}
                {enabledDemographicQuestions["marital_status"] && (
                  <Formblock
                    type="text"
                    locked={true}
                    defaultValue={activeTM?.demographics?.marital_status || "-"}
                    label={t("Marital Status")}
                  />
                )}{" "}
                {enabledDemographicQuestions["disability_status"] && (
                  <Formblock
                    type="text"
                    locked={true}
                    defaultValue={activeTM?.demographics?.disability_status || "-"}
                    label={t("Disability Status")}
                  />
                )}
              </>
            )}
            {I9?.status === "complete" && (
              <>
                <div style={{ height: 15 }}></div>

                <div className="formblock-wrapper">
                  <Label label={"I-9"} />
                  <div className="defaultString input-wrapper">
                    <a className="download-i-9-link" onClick={handleDownloadI9}>
                      {downloadingI9 ? t("Downloading...") : t("Download")}
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{ height: "60px" }}></div>
          {renderPaymentInfo()}
          {renderEmergencyContacts()}
          {renderEmploymentInformation()}
          {renderCustomFields()}
          {renderLanguageSettings()}
        </div>
      </div>
      {renderComponent()}
    </div>
  );
};

export default Profile;
