import React, { useContext, useEffect, useMemo, useState } from "react";
import { AggregatedTeamMember, MiterFilterArray, TimeOffPolicy, TimeOffUpdate } from "dashboard/miter";
import Notifier from "team-portal/utils/notifier";
import { Helmet } from "react-helmet";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import AppContext from "team-portal/contexts/app-context";
import { MiterAPI } from "team-portal/utils/miter";

type Props = {
  employee?: AggregatedTeamMember;
  timeOffPolicy?: TimeOffPolicy;
  trigger?: boolean;
};

const TimeOffUpdatesTable: React.FC<Props> = ({ employee, timeOffPolicy, trigger }) => {
  const [timeOffUpdates, setTimeOffUpdates] = useState();
  const { activeTM } = useContext(AppContext);

  useEffect(() => {
    getTimeOffUpdates();
  }, [trigger, timeOffPolicy, employee]);

  const cleanTimeOffUpdates = (data) => {
    return data.map((timeOffUpdate) => {
      const hours =
        timeOffUpdate.hours !== undefined && timeOffUpdate.hours !== null
          ? (timeOffUpdate.hours >= 0 ? "+" : "") +
            timeOffUpdate.hours.toFixed("2") +
            (timeOffUpdate.hours === 1 ? " hour" : " hours")
          : "-";

      const updated_balance =
        timeOffUpdate.updated_balance || timeOffUpdate.updated_balance === 0
          ? timeOffUpdate.updated_balance.toFixed("2") +
            (timeOffUpdate.updated_balance === 1 ? " hour" : " hours")
          : "-";

      return {
        ...timeOffUpdate,
        time_off_policy: timeOffUpdate.time_off_policy ? timeOffUpdate.time_off_policy.name : "-",
        hours: hours,
        updated_balance: updated_balance,
      };
    });
  };

  const getTimeOffUpdates = async () => {
    try {
      if (!activeTM) return;
      const filters: MiterFilterArray = [{ field: "employee", type: "string", value: activeTM._id }];

      const response = await MiterAPI.time_off.updates.retrieve_many(filters);

      if (response.error) {
        throw Error(response.error);
      }

      const cleanedTimeOffUpdates = cleanTimeOffUpdates(response);
      setTimeOffUpdates(cleanedTimeOffUpdates);
    } catch (e) {
      console.error(e);
      Notifier.error("Unable to return time off updates for this team member. We are looking into it.");
    }
  };

  const timeOffUpdatesColumns = useMemo(() => {
    const cols: ColumnConfig<TimeOffUpdate>[] = [
      {
        field: "timestamp",
        headerName: "Timestamp",
        dataType: "date",
        dateFormat: "FF",
      },
      {
        field: "time_off_policy",
        headerName: "Policy",
        dataType: "string",
      } as ColumnConfig<TimeOffUpdate>,

      {
        field: "type",
        headerName: "Type",
        dataType: "string",
      },
      {
        field: "hours",
        headerName: "Hours",
        dataType: "number",
      },
      {
        field: "updated_balance",
        headerName: "New Balance",
        dataType: "number",
      },
    ];
    return cols;
  }, [employee, timeOffPolicy]);

  /*********************************************************
   *  Functions to render the UI
   **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={"time-off-updates-table"}
        resource="time off updates"
        data={timeOffUpdates}
        columns={timeOffUpdatesColumns}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Time Off Updates | Miter</title>
      </Helmet>
      <div>{renderTable()}</div>
    </>
  );
};

export default TimeOffUpdatesTable;
