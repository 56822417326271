import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import AppContext from "./contexts/app-context";
import PortalRoutes from "./Routes";
import { useMediaQuery } from "react-responsive";
import { MiterAPI, TeamPortalUser } from "./utils/miter";
import Notifier from "./utils/notifier";
import { Loader } from "ui";
import { AggregatedI9, User } from "dashboard/miter";
import { useCheckScript } from "./utils/check";
import i18n from "./translations/i18n";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { AggregatedOnboardingChecklistItem } from "dashboard/types/onboarding-types";
import { datadogRum } from "@datadog/browser-rum";

export type UserData = {
  user: User;
  team_members: TeamPortalUser[];
  active_tm: TeamPortalUser;
  onboardLink?: string;
  onboarded: boolean;
};

function App(): React.ReactElement {
  const [user, setUser] = useState<User | undefined>();
  const [teamMembers, setTeamMembers] = useState<TeamPortalUser[] | undefined>([]);
  const [activeTM, setActiveTM] = useState<TeamPortalUser | undefined>();
  const [permissionGroups, setPermissionGroups] = useState<string[]>([]);
  const [onboardLink, setOnboardLink] = useState<string | undefined>();
  const [checkOnboardLoading, setCheckOnboardLoading] = useState(false);
  const [onboarded, setOnboarded] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [companyHasPrgs, setCompanyHasPrgs] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [onboardingChecklistItem, setOnboardingChecklistItem] =
    useState<AggregatedOnboardingChecklistItem | null>(null);
  const [i9, setI9] = useState<AggregatedI9>();

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const device = isMobile ? "mobile" : "desktop";

  useCheckScript("https://cdn.checkhq.com/component-initialize.js");

  useEffect(() => {
    Sentry.setUser({
      id: user?._id,
      username: user?.email || undefined,
    });

    Sentry.setTags({
      name: activeTM?.full_name,
      company_id: activeTM?.company._id,
      company_name: activeTM?.company?.name,
    });

    datadogRum.setUser({
      id: user?._id,
      name: activeTM?.full_name,
      email: user?.email || undefined,
      company_name: activeTM?.company?.name,
      company_id: activeTM?.company?._id,
      account_id: activeTM?._id,
      account_type: "team_member",
    });
  }, [activeTM, user]);

  // Pulls the user data from the API every time the app is loaded
  const fetchUserData = async (newActiveTm?: string): Promise<UserData | undefined> => {
    if (!localStorage.getItem("authToken")) return;
    if (!user) setFetchingUserData(true);

    try {
      const res = await MiterAPI.sessions.current(newActiveTm);
      if (res.error) throw new Error(res.error);

      if (!res.authToken || typeof res.authToken !== "string") throw new Error("Invalid auth token");

      if (!res.tm && !res.team_members?.length) throw new Error("No active team member found");

      if (res.tm) {
        const fetchedPermissionGroups = await MiterAPI.team_member.retrieve_permission_groups(res.tm._id);
        if (fetchedPermissionGroups.error) throw new Error(fetchedPermissionGroups.error);
        setPermissionGroups(fetchedPermissionGroups?.map((pg) => pg._id) || []);
      }

      localStorage.setItem("authToken", res.authToken);

      setUser(res.user);
      setTeamMembers(res.team_members);
      setActiveTM(res.tm || res.team_members?.[0]);
      setOnboardLink(res.onboardLink);
      setOnboarded(res.onboarded as boolean);
      setCompanyHasPrgs(res.companyHasPrgs);
      setI9(res.I9);
      setFetchingUserData(false);

      i18n.changeLanguage(res.tm?.language || "en");

      return {
        user: res.user,
        team_members: res.team_members,
        active_tm: res.tm,
        onboardLink: res.onboardLink,
        onboarded: res.onboarded as boolean,
      };
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      setFetchingUserData(false);
      navigate("/logout");
    }
  };

  useEffect((): void => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (!activeTM?._id) return;
    getOnboardingChecklistItem();
  }, [activeTM]);

  const onCheckOnboardEvent = async (event_name) => {
    console.log("checkonboardevent", event_name);
    if (event_name === "check-onboard-app-loaded") {
      setCheckOnboardLoading(false);
    }
  };

  const handleCheckOnboardClose = async () => {
    setCheckOnboardLoading(true);
    await fetchUserData();
    setCheckOnboardLoading(false);

    // Reload window for good measure
    window.location.reload();
  };

  const openCheckOnboard = async () => {
    if (!onboardLink) return;
    setCheckOnboardLoading(true);
    // If modal takes too long to open, redirect user to new window.
    const timeoutId = setTimeout(() => {
      const newWin = window.open(onboardLink, "_blank");
      if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
        Notifier.error(
          "Your browser blocked Miter from opening a new window. Make sure to allow popups from miter.com"
        );
      }
      setCheckOnboardLoading(false);
    }, 8000);
    try {
      const handler = window.Check.create({
        link: onboardLink,
        onClose: handleCheckOnboardClose,
        onEvent: onCheckOnboardEvent,
      });
      await handler.open();
      clearTimeout(timeoutId);
    } catch (e) {
      console.log("Error opening Check Onboard", e);
      Notifier.error("Sorry, we can't update payment information right now. We're looking into it!");
      setCheckOnboardLoading(false);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [modalOpen]);

  const getOnboardingChecklistItem = async () => {
    try {
      const res = await MiterAPI.onboarding_checklist_items.list([
        {
          field: "team_member_id",
          value: activeTM?._id,
        },
        {
          field: "archived",
          value: false,
        },
      ]);
      setOnboardingChecklistItem(res[0] || null);
    } catch (e: $TSFixMe) {
      console.error(e);
      Notifier.error("There was an error fetching the onboarding checklist. We're looking into it.");
    }
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        fetchUserData,
        onboardLink,
        teamMembers,
        setTeamMembers,
        checkOnboardLoading,
        openCheckOnboard,
        activeTM,
        setActiveTM,
        device: device,
        companyHasPrgs,
        modalOpen,
        setModalOpen,
        setOnboardLink,
        onboarded,
        setOnboarded,
        onboardingChecklistItem,
        permissionGroups,
        getOnboardingChecklistItem,
        I9: i9,
        setI9,
      }}
    >
      {fetchingUserData ? (
        <Loader />
      ) : (
        <div style={{ overflowX: "hidden" }}>
          <PortalRoutes
            enabledPortalTabs={activeTM?.company?.settings?.team?.enabled_portal_tabs}
            companyId={activeTM?.company?._id}
          />
          <Toaster />
        </div>
      )}
    </AppContext.Provider>
  );
}

export default App;
