import AppContext from "team-portal/contexts/app-context";
import { useContext, useEffect, useState } from "react";
import { BankAccountsTable } from "miter-components/bank-accounts/BankAccountsTable";
import { useTranslation } from "react-i18next";
import { InlineNetPaySplits } from "miter-components/bank-accounts/InlineNetPaySplits";
import { InlinePaymentMethodAndWithholdings } from "miter-components/onboarding/InlinePaymentMethodAndWithholdings";
import { PaymentInfoWarningBanners } from "miter-components/bank-accounts/PaymentInfoWarningBanners";

const PaymentInfo = (): JSX.Element | null => {
  const { activeTM: teamMember, fetchUserData } = useContext(AppContext);
  const { t } = useTranslation<$TSFixMe>();
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    fetchUserData(teamMember?._id);
  }, [refreshCount]);

  if (!teamMember) return <></>;

  return (
    <div>
      <h1 className="view-title">{t("Payment Info")}</h1>
      <PaymentInfoWarningBanners teamMember={teamMember} />
      <BankAccountsTable
        teamMember={teamMember}
        companyId={teamMember.company._id}
        onUpdate={() => {
          setRefreshCount(refreshCount + 1);
        }}
      />
      {teamMember.check_tm && (
        <InlinePaymentMethodAndWithholdings
          teamMember={teamMember}
          onUpdate={() => {
            setRefreshCount(refreshCount + 1);
          }}
        />
      )}
      <div className="margin-top-50">
        {teamMember.check_tm?.payment_method_preference === "direct_deposit" && (
          <InlineNetPaySplits teamMember={teamMember} refreshCount={refreshCount} />
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
